import { relative } from "path";
import React from "react";
import { NavLink } from "react-router-dom";
import Style from "../../../services/style";
import Translation from "../../../services/translations";
import RoundedActionButton from "../../subviews/rounded-action-button";

export default class LearnMoreButton extends React.Component<any, any> {
    override render(): React.ReactNode {
        return (
            <div style={{ width: "100%", height: 150, backgroundColor: Style.PINK }}>
                <div style={{ height: 150, position: "relative" }}>
                    <img
                        style={{ position: "absolute", right: 0, bottom: 0 }}
                        src={`/assets/misc/learn-more.svg`} alt="image"
                    />
                    <div style={{ padding: "5%", position: "absolute" }}>
                        <div style={{ fontWeight: 600, fontSize: 24 }}>{Translation.texts.learnMore}</div>
                            <RoundedActionButton
                                text={Translation.texts.aboutUs}
                                textColor={Style.WHITE}
                                backgroundColor={Style.BRAND}
                                width={130}
                                marginLeft={0}
                                marginTop={10}
                                height={40}
                                onClick={this.props.onClick} />
                    </div>
                </div>
            </div>
        )
    }
}