
export enum OS {
    Android,
    iOS,
    Other
}

export default class Platform {
    
    static isIOS() {
        return Platform.OS() === OS.iOS
    }

    public static isAndroid(): boolean {
        return Platform.OS() === OS.Android;
    }

    static OS(): OS {
        const ua = navigator.userAgent

        if (/android/i.test(ua)) {
            return OS.Android;
        }
        
        if (/iPad|iPhone|iPod/.test(ua)) {
            return OS.iOS;
        }

        if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
            return OS.iOS;
        }

        return OS.Other;
    }

}