
import React from "react";
import { Barcode } from "scanbot-web-sdk/@types";
import Style from "../../services/style";
import BarcodeResultToastSheet from "./subviews/barcode-result-toast-sheet";

export class BarcodeResultToastCountIndicator extends React.Component<any, any> {

    override render(): React.ReactNode {
        const size = 35;
        return (
            <div style={{
                position: "absolute",
                width: size,
                height: size,
                borderRadius: size,
                backgroundColor: Style.BRAND,
                right: -(size / 2),
                top: -(size / 2),
                textAlign: "center",
                lineHeight: (size - 2) + "px",
                color: Style.WHITE,
                fontWeight: 600,
                zIndex: 500
            }}>{this.props.count}</div>
        )
    }
}

export default class BarcodeResultToast extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            codes: [],
            hidden: true
        };
    }

    show() {
        this.setState({ hidden: false });
    }

    hide() {
        this.setState({ hidden: true });
    }

    add(codes: Barcode[]) {
        this.setState({ codes: [...this.state.codes, ...codes] });
    }

    reload(codes: Barcode[]) {
        this.setState({ hidden: false, codes: codes });
    }

    override render(): React.ReactNode {

        if (this.state.hidden) {
            return null;
        }

        const padding = 30;

        const sheetPadding = 5;
        const max = 4;
        const total = this.state.codes.length;
        const sheetCount = total < max ? total : max;
        const sheets: any[] = [];

        const maxWidth = 400;
        let width = window.innerWidth - 2 * padding;
        if (width > maxWidth) {
            width = maxWidth;
        }

        for (let i = 1; i < sheetCount + 1; i++) {
            const code = this.state.codes[total - i];
            sheets.push(<BarcodeResultToastSheet
                key={total - i}
                index={i} code={code}
                position={"absolute"}
                fullBorder={true}
                isToast={true}
                width={width}
            />)
        }

        if (this.state.codes.length === 0) {
            return null;
        }

        return (
            <div
                style={{
                    position: "fixed",
                    width: width,
                    height: 80,
                    left: `calc(50% - ${width / 2}px)`,
                    bottom: padding + (sheetPadding * sheetCount)
                }}
                onClick={() => {
                    this.props.onClick(this.state.codes);
                }}
            >
                {sheets}
                <BarcodeResultToastCountIndicator count={this.state.codes.length} />
            </div>
        )
    }
}