
export enum Route {
    Root = "/",
    Onboarding = "onboarding",
    OnboardingOnce = "onboarding-once",
    About = "about",
    Scanning = "scanning",
    Acknowledgements = "acknowledgements",
    ScanHistory = "scan-history",
    Details = "scan-details"
}

export default class Routing {
    
    static params: any;

    static openTrialPage() {
        const url = "https://docs.scanbot.io/barcode-scanner-sdk/web/introduction/?utm_source=websdk-barcode.scanbot.io&utm_medium=app&utm_campaign=web_demo_app&utm_content=try_the_sdk";
        Routing.openTab(url);
    }

    private static navigate: any;

    static initialize(navigate: any) {
        Routing.navigate = navigate;
    }

    static goTo(route: Route, params?: any) {
        // TODO find out how to properly pass params to route
        this.params = params;

        if (params && params.barcode) {
            Routing.navigate(route + "?id=" + params.barcode.id);
        } else {
            Routing.navigate(route);
        }
        
    }

    static goBack() {
        Routing.navigate(-1);
    }

    static openTab(url: string) {
        window.open(url, '_blank', 'noreferrer')
    }
}