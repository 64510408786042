
import React from "react";
import { Bars } from "react-loader-spinner";
import Style from "../../services/style";

export default class FullScreenLoader extends React.Component<any, any> {

    override render(): React.ReactNode {
        const size = 60;

        return (
            <div style={{ width: "100%", height: "100vh", position: "fixed", top: 0 }}>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    margin: `-${size * 2}px 0 0 -${size / 1.6}px`,
                    filter: ""
                }}>
                    <Bars
                        height={size}
                        width={size}
                        color={Style.BRAND}
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>

            </div>
        )
    }
}