
import React from "react";
import Style from "../../../services/style";

export enum SBCheckboxState {
    Checked,
    Unchecked,
    Indeterminate
}

export class SBCheckboxprops {
    onChange: any;
    state: SBCheckboxState = SBCheckboxState.Indeterminate;
}

export default class SBCheckbox extends React.Component<SBCheckboxprops, any> {

    reference?: HTMLInputElement | null;

    override render(): React.ReactNode {
        
        return (
            <input
                ref={ref => {
                    this.reference = ref;
                    if (this.reference !== null) {
                        this.reference!.indeterminate = this.props.state === SBCheckboxState.Indeterminate;
                    }
                }}
                type={"checkbox"}
                checked={this.props.state === SBCheckboxState.Checked}
                onChange={(event: any) => {
                    const checked = event.target.checked;
                    this.props.onChange(checked);
                }}
                style={{
                    accentColor: Style.BRAND, width: 18, height: 18,
                    marginTop: 1.5
                }}
            />
        )
    }
}