
export default class ViewUtils {

    static totalPixels() {
        const ratio = window.devicePixelRatio;
        
        return {
            innerWidth: window.innerWidth, innerHeight: window.innerHeight,
            outerWidth: window.outerWidth, outerHeight: window.outerHeight, 
            
            availWidth: window.screen.availWidth, availHeight: window.screen.availHeight,
            screenWidth: window.screen.width, screenHeight: window.screen.height,

            pixelDepth: window.screen.pixelDepth,
            ratio: ratio, 

            document: {
                clientWidth: document.documentElement.clientWidth,
                clientHeight: document.documentElement.clientHeight
            }
        };
    }

    static isExtraSmallScreen(): boolean {

        if (ViewUtils.isLargeScreen()) {
            return false;
        }

        // iPhone 12 Pro: {width: 390, height: 844}
        // iPhone SE:     {width: 375, height: 667}

        const size = ViewUtils.getScreenSize();
        if (size.width * 2 > size.height) {
            // Small screens (old phones) usually have a smaller height
            return true;
        }

        return false;
    }
    
    static shadow(): any {
        return {
            WebkitBoxShadow: "5px 6px 30px 1px rgba(0,0,0,0.75)",
            MozBoxShadow: "5px 6px 30px 1px rgba(0,0,0,0.75)",
            boxShadow: "5px 6px 30px 1px rgba(0,0,0,0.75)",
        }
    }

    static readonly ACTION_BAR_HEIGHT = 52;

    static isSmallLandscape(): boolean {
        const minimumHeightThatWorksForUi = 450;
        if (this.shortestScreenSide() >= minimumHeightThatWorksForUi) {
            return false;
        }

        const size = this.getScreenSize();
        return size.width > size.height;
    }

    static getScreenSize(): any {
        return { width: window.innerWidth, height: window.innerHeight };
    }

    static shortestScreenSide(): number {
        const size = this.getScreenSize();
        return size.width > size.height ? size.height : size.width;
    }

    static isLargeScreen(): boolean {
        return this.shortestScreenSide() >= 768;
    }

    private static preventDefaultCallback(event: any) {
        event.preventDefault();
    }

    public static disableScreenMovement(): void {
        document.addEventListener("touchmove", ViewUtils.preventDefaultCallback, { passive: false });
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        document.documentElement.style.overflow = 'hidden';
    }

    public static enableScreenMovement(): void {
        document.removeEventListener("touchmove", ViewUtils.preventDefaultCallback);
        document.documentElement.style.overflow = 'auto';
    }

    static readonly IPHONE_14_PRO_WIDTH = 393;
    static readonly IPHONE_14_PRO_MAX_WIDTH = 430;

    // Returns whether it's an iPhone 14 pro (or pro max) model. 
    // In such a case, default to Wide Angle camera
    public static isiPhone14Pro(): boolean {
        const width = window.screen.width;
        // This'll only be correct for Portrait orientation
        // Logged measurements below
        return width === ViewUtils.IPHONE_14_PRO_WIDTH || width === ViewUtils.IPHONE_14_PRO_MAX_WIDTH;
    }
}

/**

iPhone 13 Pro (Safari): {
    "innerWidth": 390,"innerHeight": 664,
    "outerWidth": 390,"outerHeight": 844,
    "availWidth": 390,"availHeight": 844,
    "screenWidth": 390,"screenHeight": 844,
    "pixelDepth": 32,
    "ratio": 3,
    "document": {"clientWidth": 390,"clientHeight": 664}
}

iPhone 14 Pro (Safari): {
    "innerWidth":393,"innerHeight":660,
    "outerWidth":393,"outerHeight":852,
    "availWidth":393,"availHeig ht": 852,
    "screenWidth":393,"screenHeight":852,
    "pixelDepth" :32,"ratio" :3,
    "document":{"clientWidth" :393,"clientHeight": 660}
}

iPhone 14 Pro (Chrome): {
    "innerWidth":393,"innerHeight":665,
    "outerWidth":393,"outerHeight" :852,
    "availWidth": 393,"availHeight":852,
    "screenWidth":393,"screenHeight":852,
    "pixelDepth" :32,"ratio" :3,
    "document":{"clientWidth":393,"clientHeight":665}
}

iPhone 13 Pro Max (Safari): {
    "innerWidth" :428", innerHeight":746,
    "outerWidth" :428", outerHeight":926,
    "availWidth":428,"availHeight":926,
    "screenWidth":428,"screenHeight":926,
    "pixelDepth":32,"r atio":3,
    "document":{"client Width":428,"clientHeight" :746}
}

iPhone 13 Pro Max (Chrome): {
    "innerWidth":428,"innerHeight":751,
    "outerWidth": 428," outerHeight":926,
    "availWidth":428,"availHeight":926,
    "screenWidth":428,"screenHeight":926,
    "pixelDepth":32,"ratio":3,
    "document":{"client Width":428,"'clientHeight" :751}
}

iPhone 14 Pro Max (Safari): {
    "innerWidth":430, "innerHeight":740,
    "outerWidth":430,"outerHeight":932,
    "availWidth":430,"availHeight":932,
    "screenWidth":430,"screenHeight":932,
    "pixelDepth":32,"ratio":3,
    "document":{"clientWidth":430,"clientHeight":740}
}

iPhone 14 Pro Max (Chrome): {
    "innerWidth":430,"innerHeight":745,
    "outerWidth":430,"outerHeight":932,
    "availWidth":430,"availHeight":932,
    "screenWidth":430,"screenHeight":932,
    "pixelDepth":32,"ratio":3,
    "document":{"clientWidth":430,"clientHeight":745}
}

*/