
import React from "react";
import { RxCaretLeft } from "react-icons/rx";
import Routing from "../../services/routing";

import Style from "../../services/style";
import ViewUtils from "../../utils/ViewUtils";
import { BarcodeScannerActionBarButton } from "../scanning/barcode-scanner-action-bar-button";
import ScanbotLogo from "./scanbot-logo";

export default class NavigationBar extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    override render(): React.ReactNode {
        const height = ViewUtils.ACTION_BAR_HEIGHT;
        return (
            <div style={{
                width: "100%",
                height: height,
                backgroundColor: Style.BRAND,
                textAlign: "center",
                lineHeight: height + "px",
                color: Style.WHITE,
                display: "flex",
                position: "fixed",
                zIndex: 999
            }}>
                {this.props.home &&
                    <BarcodeScannerActionBarButton icon={"home"} style={{
                        position: "absolute",
                        zIndex: 500,
                        width: 2 * ViewUtils.ACTION_BAR_HEIGHT
                    }}
                        onClick={() => {
                            Routing.goBack();
                        }} />}
                {this.props.back && <div
                    style={{
                        width: ViewUtils.ACTION_BAR_HEIGHT * 2,
                        height: ViewUtils.ACTION_BAR_HEIGHT,
                        position: "absolute", zIndex: 500,
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        Routing.goBack();
                    }}>
                    {/* 
                      * RxCaretLeft aligns itself center/top by default. I don't know how to properly change that.
                      * Navbar size is, luckily, static. We can adjust its position to center/left
                     */}
                    <RxCaretLeft size={30} style={{ marginLeft: -50, marginTop: 10 }} />
                </div>}
                <div style={{ position: "absolute", width: "100%" }}>
                    {this.props.text ?? <ScanbotLogo style={{ fontSize: 21 }} />}
                </div>
                {this.props.rightItem && <div style={{
                    width: ViewUtils.ACTION_BAR_HEIGHT * (this.props.wideRightItem ? 1.8 : 1.2),
                    position: "absolute",
                    height: ViewUtils.ACTION_BAR_HEIGHT,
                    right: 0,
                    cursor: "pointer"
                }}>
                    <this.props.rightItem />
                </div>}
            </div>
        );
    }
}