
import React from "react";
import ScannerButton from "./scanner-button";

import { BarcodeScannerType } from "../../../model/scanner-type";
import Translation from "../../../services/translations";
import ViewUtils from "../../../utils/ViewUtils";

export default class BarcodeScannerButtons extends React.Component<any, any> {

    override render(): React.ReactNode {
        return (
            <div>

                <h1>Web Barcode Scanner Demo</h1>

                <div style={{ fontWeight: 500, width: "100%", paddingBottom: 10 }}>
                    {Translation.texts.home.singleBarcodeTitle}
                </div>

                <div style={{ display: "flex", paddingBottom: 16 }}>
                    <ScannerButton
                        image={BarcodeScannerType.OneD}
                        text={Translation.texts.home.oneDBarcodes}
                        onClick={this.props.onButtonClick} />

                    <div style={{ width: 16 }}></div>
                    <ScannerButton
                        image={BarcodeScannerType.TwoD}
                        text={Translation.texts.home.twoDBarcodes}
                        onClick={this.props.onButtonClick} />
                </div>

                <ScannerButton
                    image={BarcodeScannerType.Any}
                    text={Translation.texts.home.anyBarcodes}
                    wide={true}
                    onClick={this.props.onButtonClick} />

                <div style={{ fontWeight: 500, width: "100%", paddingTop: 20, paddingBottom: 10 }}>
                    {Translation.texts.home.multiBarcodeTitle}
                </div>

                <div style={{ display: "flex" }}>
                    <ScannerButton
                        image={BarcodeScannerType.Batch}
                        text={Translation.texts.home.batchScanning}
                        onClick={this.props.onButtonClick} />
                    <div style={{ width: 16 }}></div>
                    <ScannerButton
                        image={BarcodeScannerType.Multi}
                        text={Translation.texts.home.multiScanning}
                        onClick={this.props.onButtonClick} />
                </div>

            </div>
        );
    }
}