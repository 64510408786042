
import React from "react";
import { ScanbotSdkService } from "../../../services/scanbot-sdk-service";
import Style from "../../../services/style";
import Translation from "../../../services/translations";
import BarcodeScannerModalSwitch from "./subviews/barcode-scanner-modal-switch";
import BaseModalContent from "./subviews/base-modal-content";

export default class ModalChildScanSettings extends BaseModalContent {

    constructor(props: any) {
        super(props);

        this.state = {
            title: Translation.texts.scanSettingsTitle,
            showFooter: true,
        };
    }

    override Content = (props: any) => {

        const settings = ScanbotSdkService.instance.getSettings();

        return (
            <div style={{ padding: 5 }}>
                <div style={{ height: 10 }} />
                <BarcodeScannerModalSwitch
                    icon={"multiple-scanning"}
                    checked={settings.multipleScanning.checked}
                    text={settings.multipleScanning.text}
                    onChange={this.props.onChange}
                />
                <div style={{ height: 15 }} />
                <BarcodeScannerModalSwitch
                    icon={"viewfinder"}
                    checked={settings.viewFinder.checked}
                    text={settings.viewFinder.text}
                    onChange={this.props.onChange}
                />
                <div style={{ height: 15 }} />
                <BarcodeScannerModalSwitch
                    icon={"beep-tone"}
                    checked={settings.beep.checked}
                    text={settings.beep.text}
                    onChange={this.props.onChange}
                />
                <div style={{ height: 15 }} />
                {/* <BarcodeScannerModalSwitch
                    icon={"ar-overlay"}
                    checked={settings.arOverlay.checked}
                    text={settings.arOverlay.text}
                    onChange={this.props.onChange}
                /> */}
            </div >
        );
    }
}