

import React from "react";
import { CSSProperties } from "react";
import { Barcode, CameraInfo } from "scanbot-web-sdk/@types";
import styled, { keyframes } from "styled-components";

import { AnimationType } from "../../model/animation-type";
import Routing, { Route } from "../../services/routing";
import { ActiveCameraInfo, ScanbotSdkService } from "../../services/scanbot-sdk-service";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import BarcodeResultModal from "./barcode-result-modal";
import BarcodeResultToast from "./barcode-result-toast";
import BarcodeScannerActionBar from "./barcode-scanner-action-bar";

export default class BaseScannerComponent extends React.Component<any, any> {

    toast?: BarcodeResultToast | null;
    modal?: BarcodeResultModal | null;

    actionBar?: BarcodeScannerActionBar | null;

    constructor(props: any) {
        super(props);
        this.state = {
            animation: {
                type: AnimationType.None
            },
            open: true
        }
    }

    containerStyle(transform: string): CSSProperties {
        return {
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 20,
            transform: transform
        };
    }

    previousDestination?: string;

    onBarcodeSwitchChange(id: string, checked: boolean): void {
        throw ("onBarcodeSwitchChange should be overridden");
    }

    onSettingsSwitchChange(id: string, checked: boolean): void {
        throw ("onSettingsSwitchChange should be overridden");
    }

    controller(scannerId: string, title: string) {
        if (this.state.animation.type === AnimationType.None) {
            return null;
        }
        const Animation = this.animation(this.state.animation.type);
        const destination = this.to(this.state.animation.type);
        this.previousDestination = destination;

        return (
            <Animation
                style={this.containerStyle(`${destination}`)}
                onAnimationStart={this.onAnimationStart.bind(this)}
                onAnimationEnd={this.onAnimationEnd.bind(this)}
            >
                <BarcodeScannerActionBar
                    ref={ref => this.actionBar = ref}
                    title={title}
                    onHomeClick={() => { this.props.onBackPress ? this.props.onBackPress() : this.pop(); }}
                    onCameraChange={(camera: ActiveCameraInfo) => {
                        ScanbotSdkService.instance.switchCamera(camera);
                        this.actionBar?.reloadCameras();
                    }}
                    onBarcodeSwitchChange={(id: string, checked: boolean) => {
                        this.onBarcodeSwitchChange(id, checked);
                    }}
                    onSettingsSwitchChange={(id: string, checked: boolean) => {
                        this.onSettingsSwitchChange(id, checked);
                    }}
                />

                <div style={{
                    height: `calc(100% - ${ViewUtils.ACTION_BAR_HEIGHT}px)`,
                    backgroundColor: "black",
                    marginTop: ViewUtils.ACTION_BAR_HEIGHT,
                }}>
                    <div id={scannerId} style={{ width: "100%", height: "100%" }} onClick={() => {
                        this.actionBar?.hideModal();
                    }} />
                    <div style={{
                        top: ViewUtils.ACTION_BAR_HEIGHT + 20,
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        textAlign: "center"
                    }}>
                        <div style={{
                            color: Style.WHITE,
                            margin: "0 auto",
                            padding: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                            left: "50%"
                        }} onClick={() => {
                            Routing.openTrialPage();
                        }}>
                            <span style={{ textDecoration: "underline" }}>{Translation.texts.trySdk}</span>
                            <span>{" →"}</span>
                        </div>
                    </div>
                </div>

                <BarcodeResultToast
                    ref={ref => this.toast = ref}
                    onClick={(codes: Barcode[]) => {
                        this.onToastClick();
                    }}
                />
                <BarcodeResultModal
                    ref={ref => this.modal = ref}
                    onViewDetails={(barcode: Barcode) => {
                        Routing.goTo(Route.Details, {barcode: barcode});
                    }}
                    onDismiss={() => {
                        this.dismissResultSheet();
                    }}
                />

            </Animation>
        );
    }

    dismissResultSheet() {
        throw("dismissResultSheet should be overridden");
    }

    onToastClick() {
        throw ("onToastClick should be overridden");
    }

    onDonePress() {
        // Empty function
    }

    private _isVisible = false;
    isVisible() {
        return this._isVisible;
    }

    push(type: AnimationType) {
        ViewUtils.disableScreenMovement();
        this._isVisible = true;
    }

    pop() {
        ViewUtils.enableScreenMovement();
        this._isVisible = false;
    }

    onAnimationStart() {
        // Empty function
    }

    onAnimationEnd() {
        if (this.state.animation.type === AnimationType.Pop) {
            this.updateAnimationType(AnimationType.None);
        }
    }

    pushType?: AnimationType;

    updateAnimationType(type: AnimationType, callback?: any) {
        this.setState({ animation: { type: type } }, callback);
    }

    animation(type: AnimationType) {
        let from = this.from(type);
        let to = this.to(type);

        // Failsafe to prevent issues with re-render. If implemented correctly, should never enter this case
        if (this.previousDestination === to) {
            from = this.previousDestination;
            to = this.previousDestination;
        }

        const animate = keyframes`from {transform: ${from};} to {transform: ${to};}`;
        return styled.div`animation: ${animate} 0.5s;`;
    }

    from(type: AnimationType) {
        if (type === AnimationType.PushRight) {
            return this.translate("X", 100);
        }
        if (type === AnimationType.PushBottom) {
            return this.translate("Y", 100);
        }

        if (type === AnimationType.Pop) {
            const axis = (this.pushType === AnimationType.PushRight) ? "X" : "Y";
            return this.translate(axis, 0);
        }
    }

    to(type: AnimationType) {
        if (type === AnimationType.PushRight) {
            return this.translate("X", 0);
        }
        if (type === AnimationType.PushBottom) {
            return this.translate("Y", 0);
        }
        if (type === AnimationType.Pop) {
            const axis = (this.pushType === AnimationType.PushRight) ? "X" : "Y";
            return this.translate(axis, 100);
        }
    }

    translate(axis: "X" | "Y", percentage: number) {
        return "translate" + axis + "(" + percentage + "%)";
    }

    componentWillUnmount() {
        this._isVisible = false;

        ViewUtils.enableScreenMovement();
    }
}