import React from "react";
import Routing from "../services/routing";
import Translation from "../services/translations";
import ViewUtils from "../utils/ViewUtils";
import { withRouter } from "../utils/withRouter";
import NavigationBar from "./subviews/navigation-bar";

// import { text } from '../assets/Libraries.txt'; // Relative path to your File

class AcknowledgementsPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        Routing.initialize(this.props.navigate);

        this.state = {
            text: ""
        };

        fetch("/Libraries.txt")
            .then(r => r.text())
            .then(text => {
                this.setState({ text: text.replace(/\n/g, "<br />") });
            });
    }

    override render(): React.ReactNode {
        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <NavigationBar back={true} text={Translation.texts.acknowledgement} />
                <div style={{ height: ViewUtils.ACTION_BAR_HEIGHT }} />
                <div
                    style={{ textAlign: "center", margin: 15, overflowWrap: "break-word" }}
                    dangerouslySetInnerHTML={{ __html: this.state.text }}
                />
            </div>
        );
    }
}

export default withRouter(AcknowledgementsPage);