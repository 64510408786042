
import React from "react";

import Routing, { Route } from "../../services/routing";
import SBStorage from "../../services/storage";
import { withRouter } from "../../utils/withRouter";
import OnboardingCarousel from "./onboarding-carousel";

class OnboardingPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);


        this.state = {

        }

        Routing.initialize(this.props.navigate);
    }

    override render(): React.ReactNode {
        return <OnboardingCarousel onDone={() => {
            SBStorage.instance.setHasVisited(true);
            Routing.goTo(Route.Root);
        }} />
    }
}

export default withRouter(OnboardingPage);