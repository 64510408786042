
import React from "react";
import Platform, { OS } from "../../services/Platform";
import Routing, { Route } from "../../services/routing";
import { ScanbotSdkService } from "../../services/scanbot-sdk-service";
import SBStorage from "../../services/storage";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import { withRouter } from "../../utils/withRouter";
import NavigationBar from "../subviews/navigation-bar";
import RoundedActionButton from "../subviews/rounded-action-button";
import ScanbotLogo from "../subviews/scanbot-logo";
import AboutButton from "./subviews/about-button";

class AboutPage extends React.Component<any, any> {

    textStyle(): any {
        return { width: "90%", marginLeft: "5%", textAlign: "center", fontSize: 15, paddingTop: 10, lineHeight: "20px" };
    }
    hintStyle(): any {
        return { ...this.textStyle(), fontSize: 12, lineHeight: "7px", color: Style.DARK_GRAY };
    }

    constructor(props: any) {
        super(props);

        this.state = {
            buttons: [
                {
                    icon: "mail",
                    text: Translation.texts.sendUsMessage,
                    action: () => {
                        const url = "https://scanbot.io/web-barcode-demo-contact/?utm_source=websdk-barcode.scanbot.io&utm_medium=app&utm_campaign=web_demo_app&utm_content=send_us_message";
                        Routing.openTab(url);
                    }
                },
                {
                    icon: "barcode-red",
                    text: Translation.texts.exploreBarcodeApp,
                    action: () => {
                        // const os = Platform.OS();
                        const url = "https://scanbot.io/demo-apps/?utm_source=websdk-barcode.scanbot.io&utm_medium=app&utm_campaign=web_demo_app&utm_content=explore_our_barcode";

                        // if (os === OS.iOS) {
                        //     url = "https://apps.apple.com/us/app/scanbot-sdk-barcode-scanning/id1644721211";
                        // } else if (os === OS.Android) {
                        //     url = "https://play.google.com/store/apps/details?id=io.scanbot.demo.barcodescanner&hl=en&gl=US";
                        // }

                        Routing.openTab(url);
                    }
                },
                {
                    icon: "screens",
                    text: Translation.texts.viewOnboarding,
                    action: () => {
                        Routing.goTo(Route.Onboarding);
                    }
                },
                {
                    icon: "imprint",
                    text: Translation.texts.imprint,
                    action: () => {
                        Routing.openTab("https://scanbot.io/imprint/");
                    }
                },
                {
                    icon: "acknowledgements",
                    text: Translation.texts.acknowledgement,
                    action: () => {
                        Routing.goTo(Route.Acknowledgements);
                    }
                },
                {
                    icon: "privacy",
                    text: Translation.texts.privacyPolicy,
                    action: () => {
                        Routing.openTab("https://scanbot.io/privacy/");
                    }
                }
            ]
        };

        Routing.initialize(this.props.navigate);
    }

    override render(): React.ReactNode {
        const version = "1.1.0";
        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <NavigationBar home={true} text={Translation.texts.aboutUs} />
                <div style={{ height: ViewUtils.ACTION_BAR_HEIGHT }} />
                <div style={{ width: "100%", height: "100%", maxWidth: 600, margin: "auto" }}>
                    <ScanbotLogo color={Style.BRAND} style={{ ...this.textStyle(), fontSize: 30, paddingTop: 30, lineHeight: "52px" }} />
                    <div style={{ ...this.textStyle(), color: Style.BRAND }}>
                        {Translation.texts.yourPartner}
                    </div>

                    <div style={{ ...this.hintStyle(), paddingTop: 22 }}>
                        {`Scanbot Web Barcode Scanner SDK v${ScanbotSdkService.instance.getVersion()}`}
                    </div>
                    <div style={{ ...this.hintStyle(), paddingBottom: 15 }}>{`Scanbot Web Barcode Demo v${version}`}</div>

                    <div style={{ ...this.textStyle() }}>
                        {Translation.texts.aboutUsDescription}
                    </div>

                    <div style={{ ...this.textStyle() }}>
                        {Translation.texts.aboutUsDescription2}
                    </div>

                    <RoundedActionButton
                        backgroundColor={Style.BRAND}
                        textColor={Style.WHITE}
                        width={"90%"}
                        maxWidth={350}
                        text={Translation.texts.trySdk}
                        onClick={() => {
                            Routing.openTrialPage();
                        }}
                    />
                    <div style={{ height: 30 }}></div>
                    {
                        this.state.buttons.map((button: any, index: number) => {
                            return <AboutButton key={index} button={button} isLast={index + 1 === this.state.buttons.length} />
                        })
                    }
                </div>
            </div>
        );
    }

}

export default withRouter(AboutPage);