
import React from "react";

import Style from "../../../services/style";

export default class OrientationNotSupportedOverlay extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            hidden: true
        }
    }

    show() {
        this.setState({ hidden: false });
    }

    hide() {
        this.setState({ hidden: true });
    }

    isVisible() {
        return !this.state.hidden;
    }

    override render(): React.ReactNode {

        if (this.state.hidden) {
            return null;
        }

        return (
            <div style={{
                position: "fixed",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: 1002,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{ color: Style.WHITE }}>
                    Landscape mode is not supported on small screens.
                </div>
            </div>
        )
    }
}