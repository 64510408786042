
import { Barcode } from "scanbot-web-sdk/@types";
import { Barcodes } from "../model/barcodes";

export default class SBStorage {

    public static instance = new SBStorage();

    HAS_VISITED_KEY = "has-visited";
    HAS_VISITED_TRUE = "true";
    public getHasVisited(): boolean { return this.get(this.HAS_VISITED_KEY) === this.HAS_VISITED_TRUE; }
    public setHasVisited(value: boolean): void {
        this.set(this.HAS_VISITED_KEY, value ? this.HAS_VISITED_TRUE : "false");
    }

    private set(key: string, value: any) {
        localStorage.setItem(key, value);
    }
    private get(key: string): any {
        return localStorage.getItem(key);
    }

    getById(id: string): Barcode | undefined {
        const result = localStorage.getItem("barcode-" + id);

        if (result === null) {
            return undefined;
        }
        const json = JSON.parse(result);
        return json as Barcode;
    }

    getBarcodes(): Barcode[] {

        const archive = [];
        const keys = Object.keys(localStorage);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key.includes("barcode-")) {
                const item = localStorage.getItem(key);
                if (item !== null) {
                    archive.push(JSON.parse(item));
                }
            }
        }

        return archive;
    }

    clearBarcodes() {
        const keys = Object.keys(localStorage);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key.includes("barcode-")) {
                localStorage.removeItem(key);
            }
        }
    }

    addAll(barcodes: Barcode[]) {
        barcodes.forEach((code: Barcode) => {
            this.add(code);
        });
    }

    add(barcode: Barcode | undefined) {

        if (barcode === undefined) {
            return;
        }

        localStorage.setItem("barcode-" + Barcodes.getIdFrom(barcode), JSON.stringify(barcode));
    }

}