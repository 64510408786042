
import React from "react";
import styled, { keyframes } from "styled-components";
import ViewUtils from "../../../utils/ViewUtils";

export enum ModalStatus {
    /**
     * Dormant state is when the view has been initialized but never called. 
     * This is to prevent flickering during initial (re-)rendering when the view is being built
     */
    Dormant,
    Open,
    Closed
}

interface BarcodeScannerModalState {
    status: ModalStatus,
    child?: any
}

export default class BarcodeScannerModal extends React.Component<any, BarcodeScannerModalState> {

    animation: any;

    invisibleY() {
        return this.translate(-(this.animation?.clientHeight + ViewUtils.ACTION_BAR_HEIGHT))
    }
    visibleY() {
        return this.translate(ViewUtils.ACTION_BAR_HEIGHT);
    }
    translate(coordinate: number) {
        return `translateY(${coordinate}px)`;
    }

    initialized = false;
    duration() {
        if (this.initialized) {
            return 0.5;
        }
        /**
         * Initial animation duration should be 0, else you'll briefly glimpse hide animation
         */
        return 0;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            // Start with it being offscreen
            status: ModalStatus.Closed
        }
    }

    show(child: any) {
        this.setState({
            child: child,
            status: ModalStatus.Open
        });
    }

    hide() {
        this.setState({
            status: ModalStatus.Closed
        });
    }

    getAnimationState(): { from: any, to: any } {
        let from, to;

        if (this.state.status === ModalStatus.Open) {
            from = this.invisibleY();
            to = this.visibleY();
        } else {
            from = this.visibleY();
            to = this.invisibleY();
        }

        return { from, to };
    }

    override render(): React.ReactNode {
        const { from, to } = this.getAnimationState();

        const animate = keyframes`from {transform: ${from};} to {transform: ${to};}`;

        // const Animation = styled.div`
        //     position: absolute; 
        //     background-color: white;
        //     width: 100%;
        //     overflow: hidden;
        //     animation: ${animate} ${this.duration()}s;`;

        const Animation = styled.div`
            max-height: ${this.state.status === ModalStatus.Open ? "100%" : "0"}px;
            overflow: hidden;
            position: absolute; 
            background-color: white;
            width: 100%;
            transition: all 1s ease-out;
        `;

        this.initialized = true;

        return (
            <Animation style={{ transform: to, ...ViewUtils.shadow() }} ref={ref => this.animation = ref}>
                {this.state.child}
            </Animation>
        );
    }
}