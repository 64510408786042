
import React from "react";
import { CameraInfo } from "scanbot-web-sdk/@types";

import Style from "../../services/style";
import ViewUtils from "../../utils/ViewUtils";
import NavigationBar from "../subviews/navigation-bar";
import { BarcodeScannerActionBarButton } from "./barcode-scanner-action-bar-button";
import BarcodeScannerModal, { ModalStatus } from "./modal/barcode-scanner-modal";
import ModalChildBarcodeTypesSelection from "./modal/modal-child-barcode-types-selection";
import ModalChildCameraSelection from "./modal/modal-child-camera-selection";
import ModalChildScanSettings from "./modal/modal-child-scan-settings";

export enum ActionBarIcons {
    Home = "home",
    Camera = "camera",
    BarcodeType = "qr-code",
    Settings = "tune"
}

export interface BarcodeScannerActionBarState {
    cameraActive?: boolean,
    barcodeTypeActive?: boolean,
    settingsActive?: boolean,
    frontCameraSelected?: boolean,
}

export default class BarcodeScannerActionBar extends React.Component<any, BarcodeScannerActionBarState> {

    modal?: BarcodeScannerModal | null;

    cameraModal?: ModalChildCameraSelection | null;
    barcodeTypeModal?: ModalChildBarcodeTypesSelection | null;
    settingsModal?: ModalChildScanSettings | null;

    constructor(props: any) {
        super(props);

        this.state = {
            cameraActive: false,
            barcodeTypeActive: false,
            settingsActive: false
        };
    }

    refreshState(state?: BarcodeScannerActionBarState) {
        this.setState({
            cameraActive: state?.cameraActive ?? false,
            barcodeTypeActive: state?.barcodeTypeActive ?? false,
            settingsActive: state?.settingsActive ?? false
        });
    }

    hideModal() {

        if (this.modal?.state.status === ModalStatus.Closed) {
            return;
        }

        this.modal?.hide();

        this.refreshState();
    }

    onCameraClick() {
        const newState = this.state.cameraActive !== true;
        this.refreshState({
            cameraActive: newState,
        });

        if (newState === false) {
            this.hideModal();
        } else {
            const modal = (<ModalChildCameraSelection
                ref={ref => this.cameraModal = ref}
                onChange={this.props.onCameraChange}
            />);
            this.modal?.show(modal);
        }
    }

    onBarcodeTypeClick() {
        const newState = this.state.barcodeTypeActive !== true;

        this.refreshState({
            barcodeTypeActive: newState,
        });

        if (newState === false) {
            this.hideModal();
        } else {
            const modal = <ModalChildBarcodeTypesSelection
                ref={ref => this.barcodeTypeModal = ref}
                onChange={this.props.onBarcodeSwitchChange}
            />;
            this.modal?.show(modal);
        }
    }

    reloadCameras() {
        this.cameraModal?.refreshCameras();
    }

    reloadBarcodeTypes() {
        this.barcodeTypeModal?.forceUpdate();
    }

    reloadSettings() {
        this.settingsModal?.forceUpdate();
    }

    onSettingsClick() {
        const newState = this.state.settingsActive !== true;

        this.refreshState({
            settingsActive: newState,
        });

        if (newState === false) {
            this.hideModal();
        } else {
            const modal = (
                <ModalChildScanSettings
                    onChange={this.props.onSettingsSwitchChange}
                    ref={ref => this.settingsModal = ref}
                />
            );
            this.modal?.show(modal);
        }
    }

    render() {

        if (this.props.hideButtons) {
            return <NavigationBar back={true} />;
        }

        const largeWidth = 600;

        return (

            <div style={{
                height: ViewUtils.ACTION_BAR_HEIGHT + "px",
                backgroundColor: Style.BRAND,
                zIndex: 999,
                position: "fixed",
                width: ViewUtils.isLargeScreen() ? `${largeWidth}px` : "100%",
                left: ViewUtils.isLargeScreen() ? `calc(50% - ${largeWidth / 2}px)` : 0,
                marginTop: ViewUtils.isLargeScreen() ? 30 : 0,
                ...ViewUtils.shadow()
            }}>
                <BarcodeScannerModal ref={ref => this.modal = ref} />

                <div style={{ position: "absolute", width: "100%", display: "flex", zIndex: 9000 }}>
                    <BarcodeScannerActionBarButton icon={ActionBarIcons.Home} onClick={this.props.onHomeClick} />
                    <BarcodeScannerActionBarButton
                        icon={ActionBarIcons.Camera}
                        onClick={this.onCameraClick.bind(this)}
                        active={this.state.cameraActive} />
                    <BarcodeScannerActionBarButton
                        icon={ActionBarIcons.BarcodeType}
                        onClick={this.onBarcodeTypeClick.bind(this)}
                        active={this.state.barcodeTypeActive} />
                    <BarcodeScannerActionBarButton
                        icon={ActionBarIcons.Settings}
                        onClick={this.onSettingsClick.bind(this)}
                        active={this.state.settingsActive} />
                </div>


            </div>



        );
    }
}
