import { Barcode, BarcodeResult } from "scanbot-web-sdk/@types";
import { BarcodeFormat } from "scanbot-web-sdk/@types/model/barcode/barcode-format";
import { AnimationType } from "../../model/animation-type";
import { Barcodes } from "../../model/barcodes";
import Routing, { Route } from "../../services/routing";
import { ScanbotSdkService, ScanSetting } from "../../services/scanbot-sdk-service";
import SoundManager from "../../services/sound-manager";
import SBStorage from "../../services/storage";
import Translation from "../../services/translations";
import BaseScannerComponent from "./base-scanner-component";
import { SelectAllBarcodes } from "./modal/modal-child-barcode-types-selection";

export default class BarcodeScannerComponent extends BaseScannerComponent {

    /**
     * Hold unique codes (no duplicates) for one camera session, 
     * these are added to persistence storage after the camera sesssion is closed
     */
    barcodes: Barcode[] = [];

    render() {
        return this.controller(ScanbotSdkService.BARCODE_SCANNER_CONTAINER, "Barcode Scanner");
    }

    async onBarcodesDetected(result: BarcodeResult) {

        if (this.props.onBarcodesDetected) {
            this.props.onBarcodesDetected(result);
        }

        let beep = false;
        let newlyFound: Barcode | undefined;

        if (!ScanbotSdkService.instance.settings.multipleScanning.checked) {
            if (result.barcodes.length > 0) {
                newlyFound = result.barcodes[0];
                this.barcodes.push(newlyFound);
                Barcodes.addDateTo(newlyFound);
                Barcodes.addIdTo(newlyFound);
                beep = true;
            }
        } else {

            result.barcodes.forEach((first: Barcode) => {
                const existing = this.barcodes.find((second: Barcode) => Barcodes.equals(first, second));
                if (existing === undefined) {
                    this.barcodes.push(first);
                    Barcodes.addDateTo(first);
                    Barcodes.addIdTo(first);
                    beep = true;
                }
            });
        }

        if (beep && ScanbotSdkService.instance.settings.beep.checked) {
            SoundManager.beep();
        }

        if (ScanbotSdkService.instance.settings.multipleScanning.checked) {

            for(const code of this.barcodes){
                const base64Image = await ScanbotSdkService.instance.sdk?.toDataUrl(code.barcodeImage);
                Barcodes.addImageTo(code, base64Image);
            }

            this.toast?.reload(this.barcodes);

            SBStorage.instance.addAll(this.barcodes);
        } else {
            if (newlyFound !== undefined) {
                this.modal?.show(newlyFound);
                ScanbotSdkService.instance.pause();
                const base64Image = await ScanbotSdkService.instance.sdk?.toDataUrl(newlyFound.barcodeImage);
                Barcodes.addImageTo(newlyFound, base64Image);
                SBStorage.instance.add(newlyFound);
            }
        }
    }

    onError(e: Error) {
        if (this.props.onError) {
            this.props.onError(e);
        }

        this.toast?.reload(this.barcodes);
    }

    override onToastClick() {
        /**
         * Clicking on toast also closes the scanner, this is the second option, 
         * also add current stash of barcodes to persistent storage
         */
        Barcodes.instance.addAll(this.barcodes);
        this.barcodes = [];
        Routing.goTo(Route.ScanHistory);
    }

    override async onBarcodeSwitchChange(id: string, checked: boolean): Promise<void> {

        if (id === SelectAllBarcodes.OneD || id === SelectAllBarcodes.TwoD) {
            ScanbotSdkService.instance.updateAllAcceptedCodes(id, checked);
        } else {
            ScanbotSdkService.instance.updateAcceptedCode(id as BarcodeFormat, checked);
        }

        this.actionBar?.reloadBarcodeTypes();
        await ScanbotSdkService.instance.createBarcodeScanner(this.onBarcodesDetected.bind(this), this.onError.bind(this));
    }

    override dismissResultSheet() {
        ScanbotSdkService.instance.resume();
        this.modal?.hide();
    }

    override async onSettingsSwitchChange(id: string, checked: boolean): Promise<void> {
        ScanbotSdkService.instance.updateSetting(id, checked);
        this.actionBar?.reloadSettings();

        if (id === Translation.texts.multiScanning && checked) {
            // Always close single-result sheet and continue scanning when enabling multi-scanning
            this.dismissResultSheet();

        }
        await ScanbotSdkService.instance.createBarcodeScanner(this.onBarcodesDetected.bind(this), this.onError.bind(this));
    }

    async push(type: AnimationType) {
        super.push(type);
        this.pushType = type;
        this.updateAnimationType(type, async () => {
            await ScanbotSdkService.instance.createBarcodeScanner(this.onBarcodesDetected.bind(this), this.onError.bind(this));
        });
    }

    pop() {
        super.pop();

        this.barcodes = [];

        this.updateAnimationType(AnimationType.Pop, () => {
            ScanbotSdkService.instance.disposeBarcodeScanner();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        ScanbotSdkService.instance.disposeBarcodeScanner();
    }
}
