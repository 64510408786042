
export default class Utils {


    static getQueryParams(url: string): any {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');
        const params: any = {};
        paramArr.map(param => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        })
        return params;
    }

    static getIdParameter(): string {
        return Utils.getQueryParams(window.location.href)["id"];
    }
}