
import React from "react";
import Style from "../../../../services/style";
import Translation from "../../../../services/translations";

export default class BaseModalContent extends React.Component<any, any> {

    Content = (props: any): JSX.Element | null => {
        throw "Modal <Content /> should be overridden";
    }

    override render(): React.ReactNode {
        return (
            <div>
                <div style={{
                    width: "100%",
                    fontWeight: 600,
                    paddingLeft: 20,
                    paddingTop: 10,
                    paddingBottom: 10
                }}>{this.state.title}</div>

                <div style={{ width: "100%", height: 1, backgroundColor: Style.GRAY }} />
                <this.Content />
                {this.state.showFooter && <div style={{
                    textAlign: "center",
                    color: Style.DARK_GRAY,
                    height: 35,
                    lineHeight: "35px",
                    fontSize: 13,
                    borderTop: "1px solid " + Style.GRAY
                }}>
                    {Translation.texts.configurationDisclaimer}
                </div>}
            </div>
        );
    }
}