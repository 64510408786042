
import React from "react";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import RoundedActionButton from "../subviews/rounded-action-button";

export class OnboardingPageProps {

    index?: number;
    image?: "reliability" | "speed" | "unlimited" | "welcome";
    title?: any;
    body?: string;

    onGetStarted?: any;
}

export default class OnboardingTab extends React.Component<OnboardingPageProps, any> {

    textStyle(): any {
        return { width: ViewUtils.isLargeScreen() ? "50%" : "70%", color: "white", textAlign: "center" };
    }

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    GetStarted = () => {

        if (this.props.onGetStarted === undefined) {
            return null;
        }

        return <RoundedActionButton
            text={Translation.texts.getStarted}
            textColor={Style.BRAND}
            backgroundColor={Style.WHITE}
            onClick={this.props.onGetStarted}
            width={ViewUtils.isLargeScreen() ? "30%" : "50%"} />
    }

    override render(): React.ReactNode {

        const size = ViewUtils.getScreenSize();
        const larger = (size.width > size.height ? size.width : size.height) / 3.5;
        const max = 300;
        const imageWidth = larger > max ? max : larger;

        const height = window.innerHeight;

        return (
            <div style={{
                height: height - 20,
                // height: `calc(100vh - ${20}px)`,
                display: ViewUtils.isSmallLandscape() ? "flex" : "inherit", width: "100%"
            }}>
                <img
                    style={{
                        display: "block",
                        marginLeft: ViewUtils.isSmallLandscape() ? "30px" : "auto",
                        marginRight: "auto",
                        width: imageWidth,
                        paddingTop: ViewUtils.isSmallLandscape() ? 0 : (ViewUtils.isLargeScreen() ? "10%" : "20%")
                    }}
                    src={`/assets/onboarding/onboarding-${this.props.image}.svg`} alt="image"
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{
                        ...this.textStyle(), fontSize: 24, paddingTop: ViewUtils.isSmallLandscape() ? 0 : 40
                    }}>{this.props.title}</div>
                    <div style={{ ...this.textStyle(), fontSize: 15, paddingTop: 20, lineHeight: "20px" }}>{this.props.body}</div>
                    <this.GetStarted />
                </div>
            </div >
        );
    }
}