
import React from "react";
import { Barcode } from "scanbot-web-sdk/@types";
import { Barcodes } from "../../model/barcodes";
import Routing, { Route } from "../../services/routing";
import SBStorage from "../../services/storage";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import BarcodeResultToastSheet from "../scanning/subviews/barcode-result-toast-sheet";

export class NoHistoryBackground extends React.Component<any, any> {
    override render(): React.ReactNode {
        return (
            <div style={{
                paddingTop: ViewUtils.isLargeScreen() ? "10%" : "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <img style={{ paddingBottom: 20 }} src={`/assets/misc/box-empty.svg`} alt="" />
                <div style={{ textAlign: "center", color: "gray" }}>{Translation.texts.noCodes}</div>
            </div>
        )
    }
}

export default class HistoryComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            list: []
        }
    }

    reload() {
        let codes = SBStorage.instance.getBarcodes();

        codes = codes.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            const dateString1 = Barcodes.getDateFrom(b);
            const dateString2 = Barcodes.getDateFrom(a);

            if (dateString1 === undefined || dateString2 === undefined) {
                return -1;
            }
            
            return new Date(dateString1).getTime() - new Date(dateString2).getTime();
        });

        const list: any[] = [];
        let counter = 0;

        codes.forEach((code: Barcode) => {
            list.push(<BarcodeResultToastSheet key={counter} code={code} onClick={() => {
                if (this.props.onBarcodeClick) {
                    this.props.onBarcodeClick(code);
                } else {
                    Routing.goTo(Route.Details, { barcode: code });
                }
            }} />)
            counter++;
        });

        this.setState({ list: list });
        
        if (this.props.onCounted) {
            this.props.onCounted(counter);
        }
    }

    override componentDidMount(): void {
        this.reload();
    }

    override render(): React.ReactNode {

        if (!this.props.visible) {
            return null;
        }

        return (
            <div style={{ width: "100%", height: "100%", maxWidth: 600, margin: "auto" }}>
                {this.state.list.length === 0 && <NoHistoryBackground />}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.state.list}
                </div>
            </div>
        )
    }
}