
import React from 'react';
import { HashRouter, Route as DOMRoute, Routes } from 'react-router-dom';
import AboutPage from './pages/about/about-page';
import AcknowledgementsPage from './pages/acknowledgements-page';
import DetailsPage from './pages/details/details-page';

import MainPage from './pages/main/main-page';
import ScanHistoryPage from './pages/history/scan-history-page';
import BarcodeScannerPage from './pages/scanning/barcode-scanner-page';
import { Route } from './services/routing';
import { ScanbotSdkService } from './services/scanbot-sdk-service';
import OnboardingPage from './pages/onboarding/onboarding-page';
import OnboardingOncePage from './pages/onboarding/onboarding-once-page';

export default class App extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            sdk: undefined,
            loading: true
        }
    }

    override async componentDidMount(): Promise<void> {
        // componentDidMount is called twice in StrictMode, this only applies to development mode.
        // For some reason, the developers of react thought this was a bit more deterministic and is intentional.
        // Anyway, so our SDK is also initalized twice, but only in develoment mode
        const sdk = await ScanbotSdkService.instance.initialize();
        this.setState({ sdk: sdk, loading: false });
    }

    render() {
        return (
            <HashRouter>
                <Routes>
                    <DOMRoute path={Route.Onboarding} element={<OnboardingPage/>}/>
                    <DOMRoute path={Route.OnboardingOnce} element={<OnboardingOncePage/>}/>
                    <DOMRoute path={Route.Root} element={<MainPage loading={this.state.loading} />} />
                    <DOMRoute path={Route.ScanHistory} element={<ScanHistoryPage />} />
                    
                    <DOMRoute path={Route.Scanning + "/" + Route.ScanHistory} element={<ScanHistoryPage />} />
                    <DOMRoute path={Route.Scanning + "/" + Route.Details} element={<DetailsPage />} />
                    <DOMRoute path={Route.Scanning + "/" + Route.ScanHistory + "/" + Route.Details} element={<DetailsPage />} />
                    <DOMRoute path={Route.Scanning} element={<BarcodeScannerPage />} />
                    
                    <DOMRoute path={Route.Details} element={<DetailsPage />} />
                    <DOMRoute path={Route.ScanHistory + "/" + Route.Details + "/"} element={<DetailsPage />} />

                    <DOMRoute path={Route.About} element={<AboutPage />} />
                    <DOMRoute path={Route.About + "/" + Route.Acknowledgements} element={<AcknowledgementsPage />} />
                    <DOMRoute path={Route.About + "/" + Route.Onboarding} element={<OnboardingPage />} />
                </Routes>
            </HashRouter>
        );
    }
}
