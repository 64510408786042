import React from "react";
import { RxCaretRight } from "react-icons/rx";
import { Barcode } from "scanbot-web-sdk/@types";
import { Barcodes } from "../../../model/barcodes";
import { ScanbotSdkService } from "../../../services/scanbot-sdk-service";
import Style from "../../../services/style";

export default class BarcodeResultToastSheet extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    override render(): React.ReactNode {

        const code: Barcode = this.props.code;

        const height = 80;
        const textHeight = { height: height / 4, lineHeight: (height / 4) + "px" };
        const index = this.props.index ?? 0;

        const format = ScanbotSdkService.instance.humanReadableCodes.get(code.format);
        const text = this.props.isToast ? "Detected " + format : format;

        const style: any = {};
        if (this.props.fullBorder) {
            style.border = "1px solid gray"
            style.borderRadius = 5;
        } else {
            style.borderBottom = "1px solid gray";
            style.borderRadius = 0;
        }

        const maxTextWidth = window.innerWidth / 3 * 2;

        const text1 = code.text;
        const text2 = format;

        const date = Barcodes.getDateString(code);

        return (
            <div
                key={this.props.index}
                style={{
                    width: "100%",
                    height: height,
                    backgroundColor: Style.WHITE,
                    position: this.props.position,
                    top: index * 5,
                    zIndex: 100 - index,
                    display: "flex",
                    cursor: "pointer",
                    ...style
                }}
                onClick={this.props.onClick}
            >
                <img
                    src={(code as any).base64Image ?? ""}
                    alt="image"
                    style={{ flex: 2, maxWidth: 70, objectFit: "contain", padding: 5, borderRadius: 5 }}
                />
                <div style={{ display: "flex", flexDirection: "column", flex: 7, overflow: "hidden", textOverflow: "ellipsis" }}>
                    <div style={{
                        ...textHeight,
                        fontSize: 15,
                        paddingTop: 6,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}>{text1}</div>
                    <div style={{
                        ...textHeight,
                        fontSize: 14,
                        color: Style.DARK_GRAY,
                        maxWidth: this.props.width ? this.props.width / 3 * 2 : maxTextWidth,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}>{text2}</div>
                    <div style={{ ...textHeight, fontSize: 13, color: Style.DARK_GRAY }}>{date}</div>
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <RxCaretRight color={Style.BRAND} size={30} style={{ marginRight: 5 }} />
                </div>
            </div>
        )
    }
}
