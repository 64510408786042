
import React from "react";
import { Barcode } from "scanbot-web-sdk/@types";
import styled, { keyframes } from "styled-components";
import { Barcodes } from "../../model/barcodes";
import { ScanbotSdkService } from "../../services/scanbot-sdk-service";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import RoundedActionButton from "../subviews/rounded-action-button";
import { ModalStatus } from "./modal/barcode-scanner-modal";

export default class BarcodeResultModal extends React.Component<any, any> {

    animation: any;
    content: any;

    invisibleY() {
        return this.translate(window.innerHeight);
    }
    visibleY() {
        if (ViewUtils.isLargeScreen()) {
            return this.translate(window.innerHeight / 2 - this.content?.clientHeight / 2);
        }

        return this.translate(window.innerHeight - this.content?.clientHeight);
    }
    translate(coordinate: number) {
        return `translateY(${coordinate}px)`;
    }

    initialized = false;
    duration() {
        if (this.initialized) {
            return 0.5;
        }
        /**
         * Initial animation duration should be 0, else you'll briefly glimpse hide animation
         */
        return 0;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            // Start with it being offscreen
            status: ModalStatus.Dormant,
            barcode: {},
            image: undefined
        }
    }

    async show(code: Barcode | undefined) {
        if (code === undefined) {
            return;
        }

        this.setState({
            status: ModalStatus.Open,
            barcode: code
        });

        const base64 = await ScanbotSdkService.instance.loadImage(code);
        
        this.setState({ image: base64 });
    }

    hide() {
        this.setState({
            status: ModalStatus.Closed
        });
    }

    getAnimationState(): { from: any, to: any } {
        let from, to;

        if (this.state.status === ModalStatus.Open) {
            from = this.invisibleY();
            to = this.visibleY();
        } else {
            from = this.visibleY();
            to = this.invisibleY();
        }

        return { from, to };
    }

    contentMeasured(): boolean {
        return this.content?.clientHeight !== undefined;
    }

    override render(): React.ReactNode {

        // if (this.state.status === ModalStatus.Dormant) {
        //     return null;
        // }

        // if (!this.contentMeasured()) {
        //     return null;
        // }

        const { from, to } = this.getAnimationState();

        const animate = keyframes`from {transform: ${from};} to {transform: ${to};}`;

        const Animation = styled.div`
            position: fixed; 
            background-color: white;
            top: ${this.visibleY()};
            width: ${ViewUtils.isLargeScreen() ? "500px" : "100%"};
            left: ${ViewUtils.isLargeScreen() ? "calc(50% - 250px)" : 0};
            height: ${400}px;
            z-index: 998;
            opacity: ${this.state.status === ModalStatus.Open ? 1 : 0};
            animation: ${animate} ${this.duration()}s;`;

        this.initialized = true;

        const date = Barcodes.getDateString(this.state.barcode);
        
        return (
            <Animation style={{ transform: to }} ref={ref => this.animation = ref}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    textAlign: "center",
                    paddingBottom: ViewUtils.isLargeScreen() ? 20 : 70,
                    backgroundColor: Style.WHITE
                }}
                    ref={ref => this.content = ref}
                >
                    <img style={{ height: 90, margin: "auto", maxWidth: "90%" }} src={this.state.image} alt="..." />
                    <div style={{ marginTop: 20 }}>{this.state.barcode.text}</div>
                    <div style={{ color: Style.DARK_GRAY, marginTop: 20, fontSize: 12 }}>{this.state.barcode.format}</div>
                    <div style={{ color: Style.DARK_GRAY, marginTop: 5, fontSize: 12 }}>{date}</div>

                    <RoundedActionButton
                        text={Translation.texts.viewDetails}
                        textColor={Style.BRAND}
                        backgroundColor={Style.WHITE}
                        onClick={() => this.props.onViewDetails(this.state.barcode)}
                        border={"1px solid " + Style.GRAY}
                        marginLeft={"5%"}
                        width={"90%"} />

                    <RoundedActionButton
                        text={Translation.texts.dismiss}
                        textColor={Style.DARK_GRAY}
                        backgroundColor={Style.WHITE}
                        onClick={this.props.onDismiss}
                        border={"1px solid " + Style.GRAY}
                        marginLeft={"5%"}
                        marginTop={15}
                        caret={false}
                        width={"90%"} />
                    <div style={{ height: 20 }} />
                </div>
            </Animation>
        );
    }
}