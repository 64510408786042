
import React from "react";

import Style from "../../services/style";
import ViewUtils from "../../utils/ViewUtils";


interface BarcodeScannerActionBarButtonProps {
    onClick: any,
    icon: any,
    style?: any,
    active?: boolean
}

export class BarcodeScannerActionBarButton extends React.Component<BarcodeScannerActionBarButtonProps> {

    static readonly ARROW_DOWN = "down";
    static readonly ARROW_UP = "up";

    constructor(props: any) {
        super(props);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    backgroundColor() {
        if (this.props.active === true) {
            return Style.BRAND_DARKER;
        }

        return Style.BRAND;
    }

    override render(): React.ReactNode {

        const size = 25;
        const padding = (ViewUtils.ACTION_BAR_HEIGHT - size) / 2;

        let arrow;
        if (this.props.active === true) {
            arrow = BarcodeScannerActionBarButton.ARROW_UP;
        } else if (this.props.active === false) {
            arrow = BarcodeScannerActionBarButton.ARROW_DOWN;
        } // else: arrow is undefined and not shown

        return (
            <div style={{
                width: "25%",
                ...this.props.style,
                color: "white",
                height: "52px",
                display: "flex",
                backgroundColor: this.backgroundColor(),
                cursor: "pointer"
            }} onClick={() => this.onClick()}>
                <img style={{
                    width: size,
                    height: size,
                    marginLeft: "auto",
                    marginRight: arrow ? "0" : "auto",
                    paddingTop: padding
                }}
                    src={`/assets/misc/navigation/${this.props.icon}.svg`} alt="image"
                />
                {arrow && <img style={{
                    height: size,
                    paddingTop: padding,
                    marginRight: "auto"
                }}
                    src={`/assets/misc/navigation/arrow-${arrow}.svg`} alt="image"
                />}
            </div>
        );
    }
}
