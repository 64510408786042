
export default class SoundManager {

    private static audio?: HTMLAudioElement;

    private static initialized() {
        return SoundManager.audio !== undefined;
    }
    public static initialize(): void {

        if (SoundManager.initialized()) {
            return;
        }

        SoundManager.audio = new Audio();
        SoundManager.audio.autoplay = true;

        // Required for iOS 14:
        // onClick of first interaction on page before I need the sounds
        // (This is a tiny MP3 file that is silent and extremely short - 
        // retrieved from https://bigsoundbank.com and then modified)
        // https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
        SoundManager.audio.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    }

    public static beep() {

        SoundManager.play('Beep.mp3');
    }

    private static play(name: string) {

        if (!SoundManager.initialized()) {
            SoundManager.initialize();
        }
        
        SoundManager.audio!.src = name;
        SoundManager.audio!.play();
    }
}