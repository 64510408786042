
import React from "react";
import { RxCaretRight } from "react-icons/rx";
import Style from "../../../services/style";

class AboutButton extends React.Component<any, any> {

    override render(): React.ReactNode {
        return (
            <div style={{
                display: "flex",
                height: 30,
                lineHeight: "30px",
                marginBottom: 10,
                paddingBottom: 5,
                borderBottom: this.props.isLast ? "none" : "0.5px solid lightgray",
                cursor: "pointer"
            }} onClick={this.props.button.action}>
                <img
                    style={{
                        display: "block", width: "20%"
                    }}
                    src={`/assets/misc/icons/${this.props.button.icon}.svg`} alt="image"
                />
                <div style={{ width: "70%", fontSize: 15, overflow: "hidden" }}>{this.props.button.text}</div>
                <RxCaretRight size={"26"} style={{ color: Style.BRAND, marginTop: 2, width: "20%" }} />
            </div>
        );
    }

}

export default AboutButton;