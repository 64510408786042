import { Barcode } from "scanbot-web-sdk/@types";
import SBStorage from "../services/storage";
import { v4 as uuidv4 } from 'uuid';

export class Barcodes {
    
    public static instance = new Barcodes();

    constructor() {
        this._list = [];
    }
    
    private _list: Barcode[] = [];

    count() {
        return this._list.length;
    }

    list() {
        return this._list;
    }

    public add(code: Barcode) {
        this._list.push(code);
    }

    public addAll(codes: Barcode[] | undefined) {

        if (codes === undefined) {
            return;
        }
        
        codes.forEach((code: Barcode) => {
            this._list.push(code);
        });
    }

    public static format(codes: Barcode[]): string {
        return JSON.stringify(codes.map((code: Barcode) => code.text + " (" + code.format + ") "));
    }

    public static equals(first: Barcode, second: Barcode) {
        // TODO maybe rawBytes comparison is necessary as well?
        return first.format === second.format && first.text === second.text;
    }

    private static readonly DATE_FIELD = "date";
    private static asAny(code: Barcode) {
        return (code as any);
    }

    public static addDateTo(code: Barcode) {
        Barcodes.asAny(code)[Barcodes.DATE_FIELD] = new Date().toString();
    }

    public static getDateFrom(code: Barcode): string | undefined {

        if (code === undefined) {
            return undefined;
        }
        
        return Barcodes.asAny(code)[Barcodes.DATE_FIELD];
    }

    public static getDateString(code: Barcode): string {
        const date = Barcodes.getDateFrom(code) ?? "";
        return new Date(date).toLocaleString();
    }

    static addImageTo(code: Barcode, base64Image: string | undefined) {
        Barcodes.asAny(code).base64Image = base64Image;
    }
    static getImageFrom(code: Barcode) {
        return Barcodes.asAny(code).base64Image;
    }

    static addIdTo(code: Barcode) {
        Barcodes.asAny(code).id = uuidv4();
    }
    static getIdFrom(code: Barcode) {
        return Barcodes.asAny(code).id;
    }
}
