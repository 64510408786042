
import { isMobile } from 'react-device-detect';

// Import SDK from webpack directory to ensure web assembly binary and worker and bundled with webpack
import ScanbotSDK from 'scanbot-web-sdk/webpack';
import { BarcodeFormat } from 'scanbot-web-sdk/@types';
// Other typings should be imported from @types
import { Barcode, BarcodeScannerConfiguration, CameraInfo, IBarcodeScannerHandle, } from 'scanbot-web-sdk/@types';

import Translation from './translations';
import ViewUtils from '../utils/ViewUtils';
import { SelectAllBarcodes } from '../pages/scanning/modal/modal-child-barcode-types-selection';
import { BarcodeScannerType } from '../model/scanner-type';
import { SBCheckboxState } from '../pages/subviews/wrappers/sb-checkbox';
import Platform from './Platform';


export class ActiveCameraInfo {
    isActive = false;
    info?: CameraInfo;
}

export class ScanSetting {
    text?: string;
    checked?: boolean;

    constructor(text: string, checked: boolean) {
        this.text = text;
        this.checked = checked;
    }
}

export class ScanSettings {

    scannerType?: BarcodeScannerType;

    multipleScanning: ScanSetting;
    viewFinder: ScanSetting;
    beep: ScanSetting;
    arOverlay: ScanSetting;

    constructor() {
        this.multipleScanning = new ScanSetting(Translation.texts.multiScanning, true);
        this.viewFinder = new ScanSetting(Translation.texts.viewFinder, true);
        this.beep = new ScanSetting(Translation.texts.beepTone, true);
        this.arOverlay = new ScanSetting(Translation.texts.arOverlay, true);
    }

    update(id: string, checked: boolean) {
        if (this.multipleScanning.text === id) {
            this.multipleScanning.checked = checked;
        }

        if (this.viewFinder.text === id) {
            this.viewFinder.checked = checked;
        }

        if (this.beep.text === id) {
            this.beep.checked = checked;
        }

        if (this.arOverlay.text === id) {
            this.arOverlay.checked = checked;
        }
    }
}

export class CheckedBarcodeFormat {
    format!: BarcodeFormat;
    checked!: boolean;
}

export class ScanbotSdkService {

    static BARCODE_SCANNER_CONTAINER = 'barcode-scanner-view';

    public static instance = new ScanbotSdkService();

    readonly license = 
        "kFd5xAIpEPZVn6qBo2HZrTcDkWiQWg" +
        "sQmCB2rPn22CW8GHi72pF6T03TEhQZ" +
        "R3ykAxf7+WSk4HlYpuay9rHv6XVuG5" +
        "Q8p+QsqA2HGzcJS9XRY5ZBS1NtZsBL" +
        "+FtMLhJJwvGCjkAxhdQUagmpMfGK3U" +
        "qfEQI98Bvh/1LzbKWl0BFhmOLGbef8" +
        "1htdz0kOoTM5r3dPenSDmQHiZTl36+" +
        "M2MXfe7gJUvOZnkTBcMYetgQXWMlbf" +
        "7pvJJgLJL5Qi+DAzGZQT3ly8QAOI/C" +
        "PEkkqBB2nKty7IEeTKVd8DiWAn3YJg" +
        "kkXBHunkXCZ5flvyNe3ywfABzfUfVo" +
        "G2Fk3zXi0tzQ==\nU2NhbmJvdFNESw" +
        "p3ZWItYmFyY29kZS1zY2FubmVyLWRl" +
        "bW8tZGVidWcuczMtZXUtd2VzdC0xLm" +
        "FtYXpvbmF3cy5jb218d2ViLWJhcmNv" +
        "ZGUtc2Nhbm5lci1kZW1vLXFhLnMzLW" +
        "V1LXdlc3QtMS5hbWF6b25hd3MuY29t" +
        "fHdlYi1iYXJjb2RlLXNjYW5uZXItZG" +
        "Vtby1pbnRlcm5hbC5zMy1ldS13ZXN0" +
        "LTEuYW1hem9uYXdzLmNvbXxsb2NhbG" +
        "hvc3R8d2Vic2RrLWJhcmNvZGUuc2Nh" +
        "bmJvdC5pbwoxNzY0NjMzNTk5CjUxMg" +
        "o4\n";

    sdk?: ScanbotSDK;

    barcodeScanner?: IBarcodeScannerHandle;

    /**
     * Initialization event handler
     */
    initialized: any;

    public async initialize() {

        /**
         * Manually copied wasm binaries to public/wasm. These files are not commited to the repository.
         * Remove engine specification, or for release builds manually copy them from 
         * node_modules/scanbot-web-sdk/bundle/bin/barcode-scanner/
         */
        this.sdk = await ScanbotSDK.initialize({
            licenseKey: this.license,
            // engine: "/" ,
            engine: '/wasm/',
            allowThreads: true,
        });

        console.log("ScanbotSDK v" + this.sdk.version);

        this.acceptedCodes = [];

        this.oneDCodes.forEach((code: BarcodeFormat) => {
            this.acceptedCodes.push({ format: code, checked: true });
        });
        this.twoDCodes.forEach((code: BarcodeFormat) => {
            this.acceptedCodes.push({ format: code, checked: true });
        });

        if (this.initialized) {
            this.initialized();
        }

        return this.sdk;
    }

    async setLicenseFailureHandler(callback: any) {
        await this.setLicenseTimeout(callback);
    }

    private async setLicenseTimeout(callback: any) {
        // Scanbot WebSDK does not offer real-time license failure handler. Simply loop to check it manually
        const info = await this.sdk?.getLicenseInfo();
        if (info && info.status !== 'Trial' && info.status !== 'Okay') {
            callback(info.description);
        } else {
            setTimeout(() => {
                this.setLicenseTimeout(callback);
            }, 2000);
        }
    }
    public async isLicenseValid(): Promise<boolean> {
        const info = await this.sdk?.getLicenseInfo();
        if (!info) {
            return false;
        }
        return info.status === 'Trial' || info.status === 'Okay';
    }

    humanReadableCodes = new Map<BarcodeFormat, string>([
        ['CODABAR', "CodaBar"],
        ['CODE_39', "Code 39"],
        ['CODE_93', "Code 93"],
        ['CODE_128', "Code 128"],
        ['EAN_8', "EAN-8"],
        ['EAN_13', "EAN-13"],
        ['ITF', "ITF"],
        ['UPC_A', "UPC-A"],
        ['UPC_E', "UPC-E"],
        ['UPC_EAN_EXTENSION', "UPC EAN Extension"],
        ['MSI_PLESSEY', "MSI Plessey"],
        ['PDF_417', "PDF417"],
        ['DATA_MATRIX', "DataMatrix"],
        ['AZTEC', "Aztec"],
        ['QR_CODE', "QRCode"],
        ["MICRO_QR_CODE", "Micro QRCode"],
        ["IATA_2_OF_5", "IATA 2 of 5"],
        ["INDUSTRIAL_2_OF_5", "Industrial 2 of 5"],
        ["CODE_25", "Code 25"],
        ["MAXICODE", "MAXICODE"],
        ["USPS_INTELLIGENT_MAIL", "USPS Intelligent Mail"],
        ["ROYAL_MAIL", "Royal Mail"],
        ["JAPAN_POST", "Japan Post"],
        ["ROYAL_TNT_POST", "Royal TNT Post"],
        ["AUSTRALIA_POST", "Australia Post"],
        ["DATABAR", "DataBar"],
        ["DATABAR_EXPANDED", "DataBar Expanded"],
        ["DATABAR_LIMITED", "DataBar Limited"],
        ["MICRO_PDF_417", "Micro PDF417"],
        ["GS1_COMPOSITE", "GS1 Composite"],
    ]);


    oneDCodes: BarcodeFormat[] = [
        'CODABAR',
        'CODE_39',
        'CODE_93',
        'CODE_128',
        'EAN_8',
        'EAN_13',
        'ITF',
        'UPC_A',
        'UPC_E',
        'UPC_EAN_EXTENSION',
        'MSI_PLESSEY',
        "IATA_2_OF_5",
        "INDUSTRIAL_2_OF_5",
        "CODE_25",
        "USPS_INTELLIGENT_MAIL",
        "ROYAL_MAIL",
        "JAPAN_POST",
        "ROYAL_TNT_POST",
        "AUSTRALIA_POST",
        "DATABAR",
        "DATABAR_EXPANDED",
    ];
    
    twoDCodes: BarcodeFormat[] = [
        'PDF_417',
        'MICRO_PDF_417',
        'DATA_MATRIX',
        'AZTEC',
        'QR_CODE',
        'MICRO_QR_CODE',
        'MAXICODE',
        'GS1_COMPOSITE',
    ];

    getCheckStateFor(id: SelectAllBarcodes): SBCheckboxState {
        if (id === SelectAllBarcodes.OneD) {
            if (this.acceptedOneDCodes().length === this.oneDCodes.length) {
                return SBCheckboxState.Checked;
            }
            if (this.acceptedOneDCodes().length === 0) {
                return SBCheckboxState.Unchecked;
            }
        }

        if (id === SelectAllBarcodes.TwoD) {
            if (this.acceptedTwoDCodes().length === this.twoDCodes.length) {
                return SBCheckboxState.Checked;
            }
            if (this.acceptedTwoDCodes().length === 0) {
                return SBCheckboxState.Unchecked;
            }
        }

        return SBCheckboxState.Indeterminate;
    }

    acceptedOneDCodes(): CheckedBarcodeFormat[] {
        return this.checkedOneDCodes().filter((code: CheckedBarcodeFormat) => code.checked);
    }
    acceptedTwoDCodes(): CheckedBarcodeFormat[] {
        return this.checkedTwoDCodes().filter((code: CheckedBarcodeFormat) => code.checked);
    }

    checkedOneDCodes(): CheckedBarcodeFormat[] {
        return this.findCheckedCodesByFormatArray(this.oneDCodes)
            .sort((one: CheckedBarcodeFormat, two: CheckedBarcodeFormat) => one.format.localeCompare(two.format));
    }

    checkedTwoDCodes(): CheckedBarcodeFormat[] {
        return this.findCheckedCodesByFormatArray(this.twoDCodes)
            .sort((one: CheckedBarcodeFormat, two: CheckedBarcodeFormat) => one.format.localeCompare(two.format));
    }

    findCheckedCodesByFormatArray(input: BarcodeFormat[]): CheckedBarcodeFormat[] {
        const result: CheckedBarcodeFormat[] = [];

        input.forEach((code: BarcodeFormat) => {
            const found = this.acceptedCodes.find((checked: CheckedBarcodeFormat) => {
                return code === checked.format;
            });

            if (found) {
                result.push(found);
            }
        })
        return result;
    }

    acceptedCodes: CheckedBarcodeFormat[] = []

    updateAllAcceptedCodes(selection: SelectAllBarcodes, checked: boolean) {
        if (selection == SelectAllBarcodes.OneD) {
            this.oneDCodes.forEach((code: BarcodeFormat) => {
                this.acceptedCodes.forEach((checkedCode: CheckedBarcodeFormat) => {
                    if (checkedCode.format === code) {
                        checkedCode.checked = checked;
                    }
                });
            });
        } else {
            this.twoDCodes.forEach((code: BarcodeFormat) => {
                this.acceptedCodes.forEach((checkedCode: CheckedBarcodeFormat) => {
                    if (checkedCode.format === code) {
                        checkedCode.checked = checked;
                    }
                });
            });
        }
    }

    updateAcceptedCode(format: BarcodeFormat, checked: boolean) {
        this.acceptedCodes.forEach((checkedCode: CheckedBarcodeFormat) => {
            if (checkedCode.format === format) {
                checkedCode.checked = checked;
            }
        });
    }

    acceptedCodeArray(): BarcodeFormat[] {
        const result: BarcodeFormat[] = [];

        this.acceptedCodes.forEach((value: CheckedBarcodeFormat) => {
            if (value.checked) {
                result.push(value.format);
            }
        });

        return result;
    }

    settings: ScanSettings = new ScanSettings();

    getSettings(): ScanSettings {
        return this.settings;
    }

    updateSetting(id: string, checked: boolean) {
        this.settings.update(id, checked);
        this.refreshViewFinderSetting();
    }

    refreshViewFinderSetting() {
        (this.barcodeScanner as any).setFinderVisible(this.settings.viewFinder.checked);
    }

    async loadImage(barcode: Barcode): Promise<string | undefined> {
        return await ScanbotSdkService.instance.sdk?.toDataUrl(barcode.barcodeImage);
    }

    private cameras: ActiveCameraInfo[] = [];

    getCameraInfo() {
        // Initialize result as new list so original could not be modified
        return [...this.cameras];
    }

    activeCamera() {
        return this.cameras.find((camera: ActiveCameraInfo) => camera.isActive);
    }

    switchCamera(camera: ActiveCameraInfo) {
        this.cameras.forEach((info: ActiveCameraInfo) => {
            info.isActive = false;
            if (info.info?.deviceId === camera.info?.deviceId) {
                info.isActive = true;
            }
        });

        this.barcodeScanner?.switchCamera(camera.info?.deviceId ?? "none", false);
    }

    activeCameraLabel() {
        const label = this.activeCamera()?.info?.label;

        if (label === undefined) {
            if (Platform.isAndroid()) {
                // Certain android devices default to wide camera by default,
                // this ensures proper primary camera is selected for those devices
                return "camera2 0, facing back";
            } else if (Platform.isIOS() && ViewUtils.isiPhone14Pro()) {
                // isiPhone14Pro only tests against viewport pixels, 
                // just in case, also check platform based on user agent
                // iPhone 14 Pro (and Pro Max) have camera issues. Default to wide camera as a workaround
                return "Back Ultra Wide Camera";
            }
        }

        return label;
    }

    onHardSwitchToPreferredCamera: any;

    public async createBarcodeScanner(callback: any, onError: (e: Error) => void) {

        const size = ViewUtils.getScreenSize();
        let proportion = ViewUtils.isLargeScreen() ? (size.width > size.height ? 0.4 : 0.5) : 0.8;

        if (size.width > size.height * 1.5) {
            if (ViewUtils.isLargeScreen()) {
                // Extreme cases of ultrawide screens, reduce proportion even further
                proportion = 0.3;

                if (size.width > size.height * 2.0) {
                    proportion = 0.25;
                }
            }

        } else if (ViewUtils.isExtraSmallScreen()) {
            proportion = 0.65;
        }

        const preferredCamera = this.activeCameraLabel();

        const config: BarcodeScannerConfiguration = {
            containerId: ScanbotSdkService.BARCODE_SCANNER_CONTAINER,
            captureDelay: 500,
            onBarcodesDetected: callback,
            barcodeFormats: this.acceptedCodeArray(),
            videoConstraints: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                // zoom: 1,
                // focusMode: "continuous",
            },
            mirrored: false,
            preferredCamera: preferredCamera,
            returnBarcodeImage: true,
            finder: {
                aspectRatio: { width: 1, height: proportion },
                visible: this.settings.viewFinder.checked,
            },
            userGuidance: { title: { text: Translation.texts.moveFinder } },
            onError: onError
        };

        if (this.sdk) {
            try {
                this.barcodeScanner?.dispose();

                this.barcodeScanner = await this.sdk.createBarcodeScanner(config);
                const cameras = await this.barcodeScanner.fetchAvailableCameras();
                const active = this.barcodeScanner.getActiveCameraInfo();

                console.log("Initialized scanner with accepted codes: ", this.acceptedCodeArray());

                this.cameras = [];
                cameras.forEach((info: CameraInfo) => {
                    const aci = new ActiveCameraInfo();
                    if (info.deviceId === active?.deviceId) {
                        aci.isActive = true;
                    }
                    aci.info = info;
                    this.cameras.push(aci);
                });

                if (preferredCamera !== active?.label) {
                    // deviceId query before starting camera will fail because it requires (1) permission 
                    // and (2) active stream, so if the default camera is incorrect by any chance, 
                    // this can, initially, only be done after creating the scanner. 
                    const preferred = this.cameras.find((info: ActiveCameraInfo) => info.info?.label === preferredCamera);
                    if (preferred !== undefined) {
                        this.switchCamera(preferred);
                        if (this.onHardSwitchToPreferredCamera !== undefined) {
                            this.onHardSwitchToPreferredCamera();
                        }
                    }
                }

            } catch (e) {
                const error = e as Error;
                console.log("Failed to initialize scanner: ", e);
                onError(e as Error);
            }
        }
    }

    public pause() {
        this.barcodeScanner?.pauseDetection();
    }

    public resume() {
        this.barcodeScanner?.resumeDetection();
    }

    public swapCameraFacing() {
        this.barcodeScanner?.swapCameraFacing(isMobile);
    }

    public disposeBarcodeScanner() {
        this.barcodeScanner?.dispose();
    }

    public getVersion() {
        // Fall back to static string in case SDK is not initialized
        return this.sdk?.version ?? "2.9.2";
    }
}
