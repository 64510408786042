
import React from "react";
import { BarcodeFormat } from "scanbot-web-sdk/@types/model/barcode/barcode-format";
import { CheckedBarcodeFormat, ScanbotSdkService } from "../../../services/scanbot-sdk-service";
import Style from "../../../services/style";
import Translation from "../../../services/translations";
import BarcodeScannerModalSwitch from "./subviews/barcode-scanner-modal-switch";
import BaseModalContent from "./subviews/base-modal-content";
import Switch from "react-switch";
import SBSwitch from "../../subviews/wrappers/sb-switch";
import SBCheckbox, { SBCheckboxState } from "../../subviews/wrappers/sb-checkbox";

export class BarcodeSelectionTab extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    override render(): React.ReactNode {
        const active = this.props.active;
        const color = active ? Style.BRAND : Style.DARK_GRAY;
        const height = this.props.height;

        return (
            <div style={{
                height: height,
                lineHeight: height + "px",
                width: 200,
                color: color,
                borderBottom: active ? ("3px solid " + color) : undefined,
                fontWeight: active ? 600 : 400,
                paddingLeft: 25
            }}
                onClick={this.props.onClick}>
                {this.props.text}
            </div>
        );
    }
}

export class BarcodeTypeSelectionTabBar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: props.activeTab
        };
    }

    onTabClick(tab: number) {
        this.setState({ activeTab: tab });
        this.props.onTabClick(tab);
    }
    override render(): React.ReactNode {
        return (
            <div style={{ display: "flex", borderBottom: "1px solid " + Style.GRAY }}>
                <BarcodeSelectionTab
                    height={this.props.height}
                    text={Translation.texts.home.oneDBarcodes}
                    active={this.state.activeTab === 0}
                    onClick={() => this.onTabClick(0)}
                />
                <BarcodeSelectionTab
                    height={this.props.height}
                    text={Translation.texts.home.twoDBarcodes}
                    active={this.state.activeTab === 1}
                    onClick={() => this.onTabClick(1)}
                />
            </div>
        );
    }
}

export enum SelectAllBarcodes {
    OneD = "1d-select-all",
    TwoD = "2d-select-all"
}

export class BarcodeTypeTab extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            checked: props.checked ?? false
        }
    }

    is2DTab(): boolean {
        let result = false;
        (this.props.codes as CheckedBarcodeFormat[]).forEach((checked: CheckedBarcodeFormat) => {
            if (checked.format === "QR_CODE") {
                result = true;
            }
        });

        return result;
    }

    override render(): React.ReactNode {
        const codes: any[] = [];
        let allChecked = true;

        this.props.codes.forEach((code: CheckedBarcodeFormat) => {
            if (!code.checked) {
                allChecked = false;
            }

            codes.push(<BarcodeScannerModalSwitch
                key={code.format}
                style={{ paddingBottom: 10 }}
                format={code.format}
                text={ScanbotSdkService.instance.humanReadableCodes.get(code.format)}
                checked={code.checked}
                onChange={this.props.onChange}
            />)
        });

        const id = this.is2DTab() ? SelectAllBarcodes.TwoD : SelectAllBarcodes.OneD;
        const cbState = ScanbotSdkService.instance.getCheckStateFor(id);

        return (
            <div style={{ ...this.props.style, display: "flex", flexDirection: "column" }}>
                <div style={{
                    borderBottom: "1px solid " + Style.GRAY,
                    lineHeight: "20px",
                    paddingLeft: 20,
                    paddingBottom: 10,
                    display: "flex"
                }}>
                    <div style={{lineHeight: "20px"}}>Select All</div>
                    <div style={{ paddingLeft: 10 }}>
                        <SBCheckbox state={cbState} onChange={(checked: boolean) => {
                            this.props.onChange(id, checked);
                        }} />
                        {/* <SBSwitch onChange={(checked: boolean) => {
                            const id = this.is2DTab() ? SelectAllBarcodes.TwoD : SelectAllBarcodes.OneD;
                            this.props.onChange(id, checked);
                        }} checked={allChecked} /> */}
                    </div>
                </div>
                {codes}
            </div>
        )
    }
}

export default class ModalChildBarcodeTypesSelection extends BaseModalContent {

    constructor(props: any) {
        super(props);

        this.state = {
            title: Translation.texts.barcodeTypeTitle,
            showFooter: true,
            activeTab: 0
        };
    }

    override Content = (props: any) => {

        const service = ScanbotSdkService.instance;
        const barHeight = 40;

        const codes = this.state.activeTab === 0 ? service.checkedOneDCodes() : service.checkedTwoDCodes();

        return (
            <div style={{ width: "100%" }}>
                <BarcodeTypeSelectionTabBar height={barHeight} activeTab={this.state.activeTab} onTabClick={(tab: number) => {
                    this.setState({ activeTab: tab });
                }} />
                <BarcodeTypeTab
                    style={{ maxHeight: 300, overflow: "scroll", paddingTop: 10 }}
                    codes={codes}
                    onChange={this.props.onChange}
                />
            </div>
        )
    }

}