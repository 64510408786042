import React from "react";
import ViewUtils from "../../utils/ViewUtils";

class Icon extends React.Component<any, any> {
    override render(): React.ReactNode {

        const size = this.props.size;
        const color = this.props.color;
        const padding = ViewUtils.ACTION_BAR_HEIGHT / 2 - size / 2;
        
        return (
            <svg style={{ paddingTop: padding, paddingRight: 10 }} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.99994 0C2.68628 0 0 2.68628 0 5.99994C0 9.3136 2.68628 11.9999 5.99994 11.9999C9.3136 11.9999 11.9773 9.33644 11.9999 6.04211C12.0001 6.02806 12.0001 6.014 12.0001 5.99994C12.0001 2.68628 9.31385 0 5.99994 0ZM8.25013 6.04211L5.99994 6.04211L5.99994 8.29206H4.50014L4.50014 4.54207L8.25013 4.54207V6.04211Z"
                    fill={color} />
                <path
                    d="M18.0001 0C14.6864 0 12.0001 2.68628 12.0001 5.99994C12.0001 6.014 12.0001 6.02806 12.0004 6.04211C12.023 9.33644 14.7005 11.9999 18.0001 11.9999C21.2997 11.9999 24 9.3136 24 5.99994C24 2.68628 21.3137 0 18.0001 0ZM19.5001 8.29206H18.0001V6.04211H15.7501V4.54207H19.5001V8.29206Z"
                    fill={color} />
                <path
                    d="M5.99994 11.9999C2.68628 11.9999 0 14.6861 0 18.0001C0 21.314 2.68628 24 5.99994 24C9.3136 24 11.9775 21.3363 11.9999 18.042C12.0001 18.0279 12.0001 18.0141 12.0001 18.0001C12.0001 14.6861 9.31385 11.9999 5.99994 11.9999ZM8.25013 19.542H4.50014V15.792H5.99994V18.042H8.25013V19.542Z"
                    fill={color} />
                <path
                    d="M18.0001 11.9999C14.6864 11.9999 12.0001 14.6861 12.0001 18.0001C12.0001 18.0141 12.0001 18.0279 12.0004 18.042C12.0227 21.3363 14.7005 24 18.0001 24C21.2997 24 24 21.3137 24 18.0001C24 14.6864 21.3137 11.9999 18.0001 11.9999ZM19.5001 19.542H15.7501V18.042H18.0001V15.792H19.5001V19.542Z"
                    fill={color} />
            </svg>
        )
    }
}

export default class ScanbotLogo extends React.Component<any, any> {
    override render(): React.ReactNode {
        
        const color = this.props.color ?? "white";
        return (
            <div style={{
                ...this.props.style,
                color: color,
                display: "flex",
                justifyContent: "center"
            }
            }>
                <Icon size={30} color={color} />
                <span style={{ fontWeight: 500 }}>Scan</span>
                <span style={{ fontWeight: 500, paddingRight: 5 }}>bot</span>
                <span style={{ fontWeight: 500 }}>SDK</span>
            </div >
        )
    }
}