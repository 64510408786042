import React from "react";
import Switch from "react-switch";
import Style from "../../../../services/style";
import SBSwitch from "../../../subviews/wrappers/sb-switch";

export default class BarcodeScannerModalSwitch extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    onChange(checked: any) {
        this.setState({ checked: checked });
        // Barcode formats have an extra format prop. 
        // If format property exists, return that as 'id', else 'text'
        this.props.onChange(this.props.format ?? this.props.text, checked);
    }

    override render(): React.ReactNode {
        const height = 30;
        return (
            <div style={{ ...this.props.style, display: "flex", height: height, width: "100%" }}>
                {this.props.icon && <img
                    style={{ flex: 2, height: 25, paddingTop: 2.5 }}
                    src={`/assets/misc/icons_settings/${this.props.icon}.svg`}
                    alt="image" />}

                <div style={{
                    paddingLeft: this.props.icon ? 0 : 20,
                    flex: this.props.icon ? 10 : 8,
                    lineHeight: height + "px", fontSize: 15
                }}>
                    {this.props.text}
                </div>

                <div style={{ flex: 2, paddingTop: 6 }}>
                    <SBSwitch onChange={this.onChange.bind(this)} checked={this.props.checked} />
                </div>

            </div>
        );
    }
}