
import React from "react";

import Routing, { Route } from "../../services/routing";
import SBStorage from "../../services/storage";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import { withRouter } from "../../utils/withRouter";

import NavigationBar from "../subviews/navigation-bar";
import HistoryComponent from "./history-component";

class ScanHistoryPage extends React.Component<any, any> {

    content?: HistoryComponent | null;

    constructor(props: any) {
        super(props);

        Routing.initialize(this.props.navigate);
        this.state = {
            barcodeCount: 0
        };
    }

    override render(): React.ReactNode {

        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <NavigationBar
                    back={true}
                    text={Translation.texts.scanHistory + " (" + this.state.barcodeCount + ")"}
                    wideRightItem={true}
                    rightItem={() => <div style={{
                        paddingRight: 10,
                        fontSize: 12,
                        fontWeight: 600,
                        opacity: (this.state.barcodeCount > 0) ? 1 : 0.5
                    }} onClick={() => {
                        SBStorage.instance.clearBarcodes();
                        this.content?.reload();
                    }}>{Translation.texts.deleteAll.toUpperCase()}</div>}
                />
                <div style={{ height: ViewUtils.ACTION_BAR_HEIGHT * 1.5 }} />
                <HistoryComponent ref={ref => this.content = ref} visible={true} onCounted={(count: number) => {
                    this.setState({ barcodeCount: count })
                }} />
            </div>
        );
    }
}

export default withRouter(ScanHistoryPage);