
import React from "react";
import Switch from "react-switch";
import Style from "../../../services/style";

export class SBSwitchprops {
    onChange: any;
    checked = false;
    height?: number;
    width?: number;
}

export default class SBSwitch extends React.Component<SBSwitchprops, any> {

    override render(): React.ReactNode {
        return (
            <Switch
                onChange={this.props.onChange}
                checked={this.props.checked}
                onColor={Style.BRAND}
                checkedIcon={false}
                uncheckedIcon={false}
                height={this.props.height ?? 21}
                width={this.props.width ?? 43}
            />
        )
    }
}