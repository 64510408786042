
import React from "react";

import { Barcodes } from "../../model/barcodes";
import Style from "../../services/style";
import Translation from "../../services/translations";
import RoundedActionButton from "../subviews/rounded-action-button";

class DetailComponentRow extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    override render(): React.ReactNode {
        return (
            <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                <div style={{ fontSize: 12, color: Style.DARK_GRAY }}>{this.props.name}</div>
                <div
                    style={{
                        border: this.props.outlined ? "1px solid " + Style.GRAY : undefined,

                        lineHeight: "30px",
                        marginTop: this.props.outlined ? 5 : 0,
                        paddingLeft: this.props.outlined ? 5 : 0,
                        borderRadius: 5,
                        fontSize: 15,
                        wordWrap: "break-word"
                    }}
                >{this.props.value}</div>
            </div>
        );
    }
}

export default class DetailsComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    isUrl(input: string) {
        const inputElement = document.createElement('input');
        inputElement.type = 'url';
        inputElement.value = input;
        return inputElement.checkValidity()
    }

    iconUrl(name: string) {
        return `/assets/misc/icons_details/${name}.svg`;
    }

    override render(): React.ReactNode {

        if (!this.props.visible) {
            return null;
        }
        const barcode = this.props.barcode;

        return (
            <div style={{ width: "100%", height: "100%", maxWidth: 600, margin: "auto" }}>
                <div style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, display: "flex", flexDirection: "column" }}>

                    <img
                        style={{ height: 90, margin: "auto", maxWidth: "90%", marginBottom: 15 }}
                        src={barcode.base64Image}
                        alt="..."
                    />

                    <DetailComponentRow name={Translation.texts.type} value={barcode?.format} />
                    <DetailComponentRow name={Translation.texts.date} value={Barcodes.getDateString(barcode)} />
                    <DetailComponentRow name={Translation.texts.value} value={barcode?.text} outlined={true} />

                    <RoundedActionButton
                        text={Translation.texts.copyValue}
                        textColor={Style.BRAND}
                        backgroundColor={Style.WHITE}
                        onClick={() => this.props.onCopyClick(barcode)}
                        border={"1px solid " + Style.GRAY}
                        marginLeft={"5%"}
                        marginTop={15}
                        caret={false}
                        iconUrl={this.iconUrl("copy")}
                        width={"90%"} />

                    {this.isUrl(barcode?.text) && <RoundedActionButton
                        text={Translation.texts.openUrl}
                        textColor={Style.BRAND}
                        backgroundColor={Style.WHITE}
                        onClick={() => this.props.onOpenUrlClick(barcode)}
                        border={"1px solid " + Style.GRAY}
                        marginLeft={"5%"}
                        marginTop={15}
                        caret={false}
                        iconUrl={this.iconUrl("open-url")}
                        width={"90%"} />}

                    <RoundedActionButton
                        text={Translation.texts.googleCode}
                        textColor={Style.BRAND}
                        backgroundColor={Style.WHITE}
                        onClick={() => this.props.onGoogleClick(barcode)}
                        border={"1px solid " + Style.GRAY}
                        marginLeft={"5%"}
                        marginTop={15}
                        caret={false}
                        iconUrl={this.iconUrl("google")}
                        width={"90%"} />
                </div>
            </div>
        );
    }
}