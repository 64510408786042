
export default class Translation {

    static get texts(): any {
        return Translation._texts[Translation.LANGUAGE];
    }

    private static LANGUAGE = "en";

    private static _texts: any = {
    
        en: {
            next: 'Next',
            previous: 'Previous',
            proceed: 'Proceed',
            skip: 'Skip',
            getStarted: 'Get Started',
            reloadPage: 'Reload Page',
            viewDetails: 'View details',
            dismiss: 'Dismiss',
            copyValue: 'Copy value',
            openUrl: 'Open URL',
            googleCode: 'Google the code',
            aboutUs: 'About Us',
            aboutUsDescription: 'The Scanbot Web Barcode Scanner Demo is an example of the performance and reliability of the Scanbot Web Barcode Scanner SDK. Integrateable in just one day, it scans any barcode within just 0.2 seconds. Even under challenging conditions such as damaged barcodes, dark lighting conditions, and difficult angles, the SDK impresses with reliability.',
            aboutUsDescription2: 'To thoroughly test the Scanbot Web Barcode Scanner SDK within your website, you can sign up for a free 30-day Proof of Concept.',
            learnMore: 'Learn more about Scanbot SDK',
            yourPartner: 'Your partner for all Data Capture needs',
            getYourLicense: 'Get your free Trial License now',
            sendUsMessage: 'Send us a message',
            exploreBarcodeApp: 'Explore our Barcode Demo App',
            viewOnboarding: 'View onboarding screens',
            imprint: 'Imprint',
            acknowledgement: 'Acknowledgements',
            privacyPolicy: 'Privacy Policy',
            cameraPermissionError: 'This Web Demo requires access to your camera to start the scanning process.',
            enablePermissionInSettings: 'Go to your Browser settings to enable camera access, then refresh this page',
            trySdk: 'Try the SDK in your project',
            finderText: 'Move the barcode into the finder',
            selectCameraTitle: 'Select Camera',
            cameraFront: 'FaceTime HD Camera (Built-in)', //TODO: Change this
            cameraRear: 'LG UltraFine Display Camera', //TODO: Change this
            barcodeTypeTitle: 'Choose Barcode type',
            configurationDisclaimer: 'All changes are applied automatically',
            scanSettingsTitle: 'Scan Settings',
            multiScanning: 'Multiple Scanning',
            viewFinder: 'Viewfinder',
            beepTone: 'Beep Tone',
            arOverlay: 'AR Overlay',
            code: 'Code',
            type: 'Type',
            date: 'Date',
            value: 'Value',
            showFullData: 'Show full data.',
            seeHistory: 'See History',
            scanHistory: 'Scan History',
            deleteAll: 'Delete all',
            noCodes: "No codes scanned!",
            moveFinder: "Move the finder over a barcode",
            barcodeDetails: "Barcode Details",
            onboarding: {
                welcomeDescription: 'Fast and reliable barcode scanning in your mobile apps and websites',
                reliabilityTitle: 'Reliability',
                reliabilityDescription:
                    'Mobile Barcode Scanning needs to be reliable. The Scanbot SDK scans with an accuracy of 98.7% or higher.',
                speedTitle: 'Speed',
                speedDescription:
                    'Scanbot SDK boosts your user experience with a scanning speed of just 0.2 seconds.',
                unlimitedScanningTitle: 'Unlimited Scanning',
                unlimitedScanningDescription: `We don't charge based on volume variables like number of scans, users, or downloads. Unlimited scanning at a fixed price.`
            },
            home: {
                singleBarcodeTitle: 'Discover Single Barcode Scanning',
                multiBarcodeTitle: 'Experience Multiple Barcode Scanning',
                oneDBarcodes: '1D Barcodes',
                twoDBarcodes: '2D Barcodes',
                anyBarcodes: 'Scan any Barcode',
                batchScanning: 'Batch Scanning',
                multiScanning: 'Multiple Scanning'
            }
        }
    };
    
}