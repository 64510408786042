import React from "react";
import SBStorage from "../../services/storage";
import { Navigate } from "react-router-dom";
import { Route } from "../../services/routing";


export default class OnboardingOncePage extends React.Component<any, any> {
    render() {
        if (!SBStorage.instance.getHasVisited()) {
            return <Navigate to={`/${Route.Onboarding}`} />
        } else {
            return <Navigate to={Route.Root} />
        }
    }
}