
import React from "react";
import { RxCaretRight } from "react-icons/rx";

import Style from "../../../services/style";
import { BarcodeScannerType } from "../../../model/scanner-type";
import ViewUtils from "../../../utils/ViewUtils";

export class ScannerButtonProps {

    image?: BarcodeScannerType;
    text?: string;

    wide?: boolean;

    onClick?: any;
}

export default class ScannerButton extends React.Component<ScannerButtonProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    override render(): React.ReactNode {

        return (
            <div
                style={{ backgroundColor: Style.LIGHT_GRAY, width: this.props.wide ? "100%" : "50%", cursor: "pointer" }}
                onClick={() => this.props?.onClick(this.props.image)}
            >
                <div style={{
                    display: this.props.wide ? "flex" : "block",
                    paddingLeft: 8,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 3
                }}>
                    <img
                        style={{ marginTop: this.props.wide ? -6 : -2 }}
                        src={`/assets/barcodes/${this.props.image}.svg`}
                        alt="image"
                    />
                    <div style={{ display: "flex", paddingBottom: 5, width: "100%" }}>
                        <div style={{
                            fontWeight: 500,
                            fontSize: 13,
                            paddingTop: this.props.wide ? 10 : ViewUtils.isLargeScreen() ? 50 : 10,
                            paddingLeft: this.props.wide ? 10 : 0,
                            paddingBottom: this.props.wide ? 10 : 0
                        }}>
                            {this.props.text}
                        </div>

                        <RxCaretRight size={"26"} style={{
                            color: Style.BRAND,
                            marginLeft: "auto",
                            marginTop: this.props.wide ? 5 : 4
                        }} />
                    </div>
                </div>
            </div>

        );
    }
}