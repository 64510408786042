export default class Style {

    static readonly BRAND = '#C8193C';

    static readonly BRAND_DARKER = '#A10B2E';
    
    static readonly LIGHT_GRAY = 'rgb(242, 247, 246)';
    
    static readonly GRAY = 'rgb(200, 200, 200)';

    static readonly DARK_GRAY = 'rgb(100, 100, 100)';
    
    static readonly PINK = '#FFEDED';
    
    static readonly WHITE = "white";

    static readonly TRANSPARENTGRAY: 'rgba(100, 100, 100, 50)';
}
