
import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import SBStorage from "../../services/storage";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ScanbotLogo from "../subviews/scanbot-logo";
import OnboardingTab from "./onboarding-tab";

export default class OnboardingCarousel extends React.Component<any, any> {

    textButtonStyle(): any {
        return { lineHeight: "40px", height: 40, color: "white", fontWeight: 500 }
    }

    constructor(props: any) {
        super(props);

        const texts = Translation.texts.onboarding;

        this.state = {
            showCarousel: false,
            onboarding: {
                index: 0,
                content: [
                    {
                        image: "reliability",
                        title: texts.reliabilityTitle,
                        body: texts.reliabilityDescription,
                    },
                    {
                        image: "speed",
                        title: texts.speedTitle,
                        body: texts.speedDescription,
                    },
                    {
                        image: "unlimited",
                        title: texts.unlimitedScanningTitle,
                        body: texts.unlimitedScanningDescription,
                    },
                ]
            }
        }

        window.addEventListener('orientationchange', () => {
            // After orientationchange, add a one-time resize event
            const afterOrientationChange = () => {
                // YOUR POST-ORIENTATION CODE HERE
                // Remove the resize event listener after it has executed
                setTimeout(() => {
                    this.forceUpdate();
                }, 300);
                window.removeEventListener('resize', afterOrientationChange);
            };
            this.forceUpdate();

            window.addEventListener('resize', afterOrientationChange);
        });
    }

    size?: { width: number; height: number; };

    setOnboardingPage(index: number) {
        this.setState({ onboarding: { ...this.state.onboarding, index: index } });
    }

    dismissOnboarding() {
        this.setState({ onboarding: { ...this.state.onboarding, show: false } }, () => {
            SBStorage.instance.setHasVisited(true);
        });
    }

    TopNavigation = () => {

        if (!this.state.showCarousel || this.isOnLastPage()) {
            return null;
        }

        return (
            <div style={{
                position: "fixed", width: "100%", right: 5, top: 5, display: "flex", zIndex: 50000, backgroundColor: "green"
            }}>
                <div style={{ ...this.textButtonStyle(), position: "absolute", right: 0, width: 70, textAlign: "center" }} onClick={this.props.onDone}>
                    {Translation.texts.skip}
                </div>
            </div >
        );
    }

    BottomNavigation = () => {

        const buttonStyle: any = { display: "flex", right: 5, bottom: 0, position: "absolute" };

        return (
            <div style={{ display: "flex", position: "fixed", width: "100%", bottom: 20, zIndex: 50000 }}>

                {<div style={{ width: "50%", paddingLeft: 15 }}>
                    {this.canGoBack() && <div style={{ display: "flex" }} onClick={() => {
                        this.carousel?.onClickPrev();
                        this.forceUpdate();
                    }}>
                        <RxCaretLeft size={"22"} style={{ color: "white", marginTop: 10 }} />
                        <div style={this.textButtonStyle()}>{Translation.texts.previous}</div>
                    </div>}
                </div>}

                <div style={{ width: "50%", paddingRight: 15 }}>
                    {(!this.isOnLastPage() && this.state.showCarousel) &&
                        <div style={buttonStyle} onClick={() => {
                            this.carousel?.onClickNext();
                            this.forceUpdate();
                        }}>
                            <div style={this.textButtonStyle()}>{Translation.texts.next}</div>
                            <RxCaretRight size={"22"} style={{ color: "white", marginTop: 10 }} />
                        </div>}
                    {this.isOnLastPage() && <div style={buttonStyle} onClick={() => {
                        this.props.onDone();
                    }}>
                        <div style={this.textButtonStyle()}>{Translation.texts.proceed}</div>
                        <RxCaretRight size={"22"} style={{ color: "white", marginTop: 10 }} />
                    </div>}
                </div>

            </div>
        );
    }

    GetStarted = () => {

        if (this.state.showCarousel) {
            return null;
        }

        return (
            <OnboardingTab
                image="welcome"
                title={<ScanbotLogo style={{ lineHeight: "52px" }} />}
                body={Translation.texts.onboarding.welcomeDescription}
                onGetStarted={() => {
                    this.setState({ showCarousel: true });
                }}
            />
        );
    }

    Carousel = () => {
        if (!this.state.showCarousel) {
            return null;
        }
        return (
            <Carousel
                ref={ref => this.carousel = ref}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                onChange={(index: number, item: any) => {
                    this.activeIndex = index;
                    this.forceUpdate();
                }}
            >
                {this.state.onboarding.content.map((page: any, index: number) => {
                    return <OnboardingTab
                        key={index}
                        index={index}
                        image={page.image}
                        title={page.title}
                        body={page.body}
                    />;
                })}
            </Carousel>
        );
    }

    carousel?: Carousel | null;
    activeIndex = 0;

    canGoBack() {
        return this.activeIndex > 0;
    }

    isOnFirstPage() {
        return this.activeIndex === 0;
    }

    isOnLastPage() {
        return this.activeIndex + 1 === this.state.onboarding.content.length;
    }

    override render(): React.ReactNode {
        return (
            <div style={{ backgroundColor: Style.BRAND, width: "100%", height: window.innerHeight }}>
                <this.TopNavigation />
                <this.GetStarted />
                <this.Carousel />

                <this.BottomNavigation />
            </div >
        );
    }
}