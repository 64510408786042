import React from "react";
import { Barcode, BarcodeResult } from "scanbot-web-sdk/@types";
import { BarcodeFormat } from "scanbot-web-sdk/@types/model/barcode/barcode-format";
import { Barcodes } from "../../model/barcodes";
import Platform from "../../services/Platform";
import Routing, { Route } from "../../services/routing";
import { ActiveCameraInfo, ScanbotSdkService } from "../../services/scanbot-sdk-service";
import SoundManager from "../../services/sound-manager";
import SBStorage from "../../services/storage";
import Style from "../../services/style";
import Translation from "../../services/translations";
import ViewUtils from "../../utils/ViewUtils";
import { withRouter } from "../../utils/withRouter";
import RoundedActionButton from "../subviews/rounded-action-button";
import BarcodeResultModal from "./barcode-result-modal";
import BarcodeResultToast from "./barcode-result-toast";
import BarcodeScannerActionBar from "./barcode-scanner-action-bar";
import { SelectAllBarcodes } from "./modal/modal-child-barcode-types-selection";
import OrientationNotSupportedOverlay from "./subviews/OrientatationNotSupportedOverlay";

class BarcodeScannerPage extends React.Component<any, any> {

    /**
     * Hold unique codes (no duplicates) for one camera session, 
     * these are added to persistence storage after the camera session is closed
     */
    barcodes: Barcode[] = [];

    toast?: BarcodeResultToast | null;
    modal?: BarcodeResultModal | null;

    actionBar?: BarcodeScannerActionBar | null;

    unsupportedModeOverlay?: OrientationNotSupportedOverlay | null;

    constructor(props: any) {
        super(props);

        this.state = {
            cameraDeniedOrMissing: false
        };

        Routing.initialize(this.props.navigate);
    }

    processing = false;
    async onBarcodesDetected(result: BarcodeResult) {

        if (this.unsupportedModeOverlay?.isVisible()) {
            return;
        }

        if (ScanbotSdkService.instance.acceptedCodeArray().length === 0) {
            // If no barcodes are accepted, just don't do anything.
            // This should probably be better regulated on the SDK level, 
            // i.e. what should happen if there are no accepted codes? I'd say that's an invalid argument. 
            // But for the sake of UX, in this scenario, it would make sense that it just recognizes nothing.
            return;
        }

        if (this.processing) {
            return;
        }
        this.processing = true;

        if (this.props.onBarcodesDetected) {
            this.props.onBarcodesDetected(result);
        }

        let beep = false;
        let newlyFound: Barcode | undefined;

        if (!ScanbotSdkService.instance.settings.multipleScanning.checked) {
            if (result.barcodes.length > 0) {
                newlyFound = result.barcodes[0];
                this.barcodes.push(newlyFound);
                Barcodes.addDateTo(newlyFound);
                Barcodes.addIdTo(newlyFound);
                beep = true;
            }
        } else {

            result.barcodes.forEach((first: Barcode) => {
                const existing = this.barcodes.find((second: Barcode) => Barcodes.equals(first, second));
                if (existing === undefined) {
                    this.barcodes.push(first);
                    Barcodes.addDateTo(first);
                    Barcodes.addIdTo(first);
                    beep = true;
                }
            });
        }

        if (beep && ScanbotSdkService.instance.settings.beep.checked) {
            SoundManager.beep();
        }

        if (ScanbotSdkService.instance.settings.multipleScanning.checked) {

            for(const code of this.barcodes){
                const base64Image = await ScanbotSdkService.instance.sdk?.toDataUrl(code.barcodeImage);
                Barcodes.addImageTo(code, base64Image);
            }

            this.toast?.reload(this.barcodes);

            SBStorage.instance.addAll(this.barcodes);
        } else {
            if (newlyFound !== undefined) {
                this.modal?.show(newlyFound);
                ScanbotSdkService.instance.pause();
                const base64Image = await ScanbotSdkService.instance.sdk?.toDataUrl(newlyFound.barcodeImage);
                Barcodes.addImageTo(newlyFound, base64Image);
                SBStorage.instance.add(newlyFound);
            }
        }

        this.processing = false;
    }

    onError(e: Error) {
        if (e?.name === "UnsupportedMediaDevicesError") {
            this.setState({ cameraDeniedOrMissing: true });
        }

        this.toast?.reload(this.barcodes);
    }

    async onBarcodeSwitchChange(id: string, checked: boolean): Promise<void> {

        if (id === SelectAllBarcodes.OneD || id === SelectAllBarcodes.TwoD) {
            ScanbotSdkService.instance.updateAllAcceptedCodes(id, checked);
        } else {
            ScanbotSdkService.instance.updateAcceptedCode(id as BarcodeFormat, checked);
        }

        this.actionBar?.reloadBarcodeTypes();
        await this.createScanner();
    }

    dismissResultSheet() {
        ScanbotSdkService.instance.resume();
        this.modal?.hide();
    }

    async onSettingsSwitchChange(id: string, checked: boolean): Promise<void> {
        ScanbotSdkService.instance.updateSetting(id, checked);
        this.actionBar?.reloadSettings();

        if (id === Translation.texts.multiScanning) {
            // Always close result popups when chaning scanning mode
            this.dismissResultSheet();
            this.toast?.hide();
        }

        await this.createScanner();
    }

    async createScanner() {
        await ScanbotSdkService.instance.createBarcodeScanner(
            this.onBarcodesDetected.bind(this),
            this.onError.bind(this)
        );
        this.actionBar?.cameraModal?.refreshCameras();
    }

    onToastClick() {
        Barcodes.instance.addAll(this.barcodes);
        this.barcodes = [];

        Routing.goTo(Route.ScanHistory);
    }

    override async componentDidMount(): Promise<void> {

        this.showOverlay();

        ScanbotSdkService.instance.initialized = async () => {
            await this.createScanner();
        }
        await this.createScanner();

        // Re-try show overlay after creating scanner. It's safe to call this any number of times
        // Necessary if permission alert was shown, then orientation changed, and then permission accepted
        this.showOverlay();

        const portrait = window.matchMedia("(orientation: portrait)");
        portrait.addEventListener("change", this.onOrientationChange.bind(this));

        ScanbotSdkService.instance.onHardSwitchToPreferredCamera = this.onHardSwitchToPreferredCamera;
    }

    override async componentWillUnmount(): Promise<void> {
        await ScanbotSdkService.instance.disposeBarcodeScanner();

        const portrait = window.matchMedia("(orientation: portrait)");
        portrait.removeEventListener("change", this.onOrientationChange.bind(this));

        ScanbotSdkService.instance.onHardSwitchToPreferredCamera = undefined;
    }

    onHardSwitchToPreferredCamera() {
        this.actionBar?.reloadCameras();
    }

    onOrientationChange() {
        // On some browsers (Orion on iOS), the new window size is not yet available when this event is fired.
        window.setTimeout(() => {
            this.showOverlay();
        }, 100);
    }

    showOverlay() {
        const showUnsupportedModeOverlay = ViewUtils.isSmallLandscape();
        if (showUnsupportedModeOverlay) {
            this.unsupportedModeOverlay?.show();
            ScanbotSdkService.instance.pause();
        } else {
            this.unsupportedModeOverlay?.hide();
            ScanbotSdkService.instance.resume();
        }
    }

    ScannerComponent = () => {

        const containerStyle: any = {
            height: `100%`,
            position: "fixed",
            width: "100%"
        }

        if (this.state.cameraDeniedOrMissing) {
            return (
                <div style={{ ...containerStyle, backgroundColor: Style.WHITE }}>
                    <div style={{ width: "70%", margin: "auto", textAlign: "center", marginTop: 100 }}>
                        <img style={{}}
                            src={`/assets/misc/icons/no-camera.svg`} alt="image"
                        />
                        <div style={{
                            marginBottom: 40,
                            marginTop: 10,
                            fontWeight: 400
                        }}>
                            {Translation.texts.cameraPermissionError}
                        </div>
                        {Platform.isAndroid() &&
                            <div style={{ marginBottom: 40 }}>{Translation.texts.enablePermissionInSettings}</div>
                        }
                        <RoundedActionButton
                            text={Translation.texts.reloadPage}
                            textColor={Style.BRAND}
                            backgroundColor={Style.WHITE}
                            onClick={() => { location.reload(); }}
                            border={"1px solid " + Style.GRAY}
                            marginLeft={"5%"}
                            caret={false}
                            iconUrl={`/assets/misc/icons/refresh.svg`}
                            width={"90%"} />
                    </div>
                </div>
            );
        }

        const scannerId = ScanbotSdkService.BARCODE_SCANNER_CONTAINER;

        return (
            <div style={{ ...containerStyle, backgroundColor: "black" }}>
                <div id={scannerId} style={{ width: "100%", height: "100%" }} onClick={() => {
                    this.actionBar?.hideModal();
                }} />
                <div style={{
                    top: ViewUtils.isLargeScreen() ? ViewUtils.ACTION_BAR_HEIGHT * 2 : ViewUtils.ACTION_BAR_HEIGHT + 20,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    textAlign: "center"
                }}>
                    <div style={{
                        color: Style.WHITE,
                        margin: "0 auto",
                        padding: 5,
                        borderRadius: 5,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        left: "50%"
                    }} onClick={() => {
                        Routing.openTrialPage();
                    }}>
                        <span style={{ textDecoration: "underline" }}>{Translation.texts.trySdk}</span>
                        <span>{" →"}</span>
                    </div>
                </div>
            </div>
        )
    }

    override render(): React.ReactNode {
        return (
            <div style={{ width: "100%", height: "100vh", backgroundColor: "black" }}>
                <BarcodeScannerActionBar
                    ref={ref => this.actionBar = ref}
                    hideButtons={this.state.cameraDeniedOrMissing}
                    onHomeClick={() => {
                        Routing.goBack();
                    }}
                    onCameraChange={(camera: ActiveCameraInfo) => {
                        ScanbotSdkService.instance.switchCamera(camera);
                        this.actionBar?.reloadCameras();
                        this.actionBar?.hideModal();
                    }}
                    onBarcodeSwitchChange={(id: string, checked: boolean) => {
                        this.onBarcodeSwitchChange(id, checked);
                    }}
                    onSettingsSwitchChange={(id: string, checked: boolean) => {
                        this.onSettingsSwitchChange(id, checked);
                    }}
                />

                <this.ScannerComponent />

                <BarcodeResultToast
                    ref={ref => this.toast = ref}
                    onClick={(codes: Barcode[]) => {
                        this.onToastClick();
                    }}
                />
                <BarcodeResultModal
                    ref={ref => this.modal = ref}
                    onViewDetails={(barcode: Barcode) => {
                        Routing.goTo(Route.Details, { barcode: barcode });
                    }}
                    onDismiss={() => {
                        this.dismissResultSheet();
                    }}
                />
                <OrientationNotSupportedOverlay ref={ref => {
                    this.unsupportedModeOverlay = ref;
                }} />
            </div>
        );
    }
}

export default withRouter(BarcodeScannerPage);