
import React from "react";
import { Navigate } from "react-router-dom";

import BarcodeScannerButtons from "./subviews/barcode-scanner-buttons";
import BarcodeScannerComponent from "../scanning/barcode-scanner-component";
import NavigationBar from "../subviews/navigation-bar";
import LearnMoreButton from "./subviews/learn-more-button";
import FullScreenLoader from "../subviews/full-screen-loader";

import { BarcodeScannerType } from "../../model/scanner-type";
import { withRouter } from "../../utils/withRouter";
import { Barcodes } from "../../model/barcodes";
import { SelectAllBarcodes } from "../scanning/modal/modal-child-barcode-types-selection";
import ViewUtils from "../../utils/ViewUtils";

import SBStorage from "../../services/storage";
import Routing, { Route } from "../../services/routing";
import { ScanbotSdkService } from "../../services/scanbot-sdk-service";
import SoundManager from "../../services/sound-manager";
import Platform from "../../services/Platform";

class MainPage extends React.Component<any, any> {

    scanner?: BarcodeScannerComponent | null;

    constructor(props: any) {
        super(props);


        this.state = {
            onboarding: {
                show: !SBStorage.instance.getHasVisited()
            }
        }

        Routing.initialize(this.props.navigate);
    }

    onHashChange() {
        // We add the #scanning anchor to implement browser back button functionality, 
        // to ensure that properly closes the scanner. 
        // Soft home button on the navbar therefore also manipulates the hash
        if (window.location.hash === "") {
            this.scanner?.pop();
            Barcodes.instance.addAll(this.scanner?.barcodes);
            try {
                // Clear barcodes from session storage
                this.scanner!.barcodes = [];
            } catch {
                // Scanner component may be disposed at this point. 
                // No need for further action if it has already been disposed.
            }

        }
    }

    HistoryButton = (props: any) => {
        const enabled = SBStorage.instance.getBarcodes().length > 0;

        return (
            <div style={{ width: "100%", height: "100%", opacity: enabled ? 1 : 0.5 }}>
                <img
                    style={{ paddingTop: ViewUtils.ACTION_BAR_HEIGHT / 2 - 18 / 2 }}
                    src={`/assets/misc/icons/scan-history.svg`}
                    onClick={enabled ? () => { Routing.goTo(Route.ScanHistory); } : undefined}
                />
            </div>
        )
    }

    onBarcodeButtonClick(type: BarcodeScannerType) {

        SoundManager.initialize();

        const service = ScanbotSdkService.instance;

        service.settings.beep.checked = true;
        service.settings.scannerType = type;

        if (type === BarcodeScannerType.OneD || type === BarcodeScannerType.TwoD || type === BarcodeScannerType.Any) {

            service.settings.multipleScanning.checked = false;
            service.settings.viewFinder.checked = true;

            if (type === BarcodeScannerType.OneD) {
                service.updateAllAcceptedCodes(SelectAllBarcodes.OneD, true);
                service.updateAllAcceptedCodes(SelectAllBarcodes.TwoD, false);
            }

            if (type === BarcodeScannerType.TwoD) {
                service.updateAllAcceptedCodes(SelectAllBarcodes.OneD, false);
                service.updateAllAcceptedCodes(SelectAllBarcodes.TwoD, true);
            }

            if (type === BarcodeScannerType.Any) {
                service.updateAllAcceptedCodes(SelectAllBarcodes.OneD, true);
                service.updateAllAcceptedCodes(SelectAllBarcodes.TwoD, true);
            }
        }

        if (type === BarcodeScannerType.Batch || type === BarcodeScannerType.Multi) {

            service.updateAllAcceptedCodes(SelectAllBarcodes.OneD, true);
            service.updateAllAcceptedCodes(SelectAllBarcodes.TwoD, true);
            service.settings.multipleScanning.checked = true;

            if (type === BarcodeScannerType.Batch) {
                service.settings.viewFinder.checked = true;
            }

            if (type === BarcodeScannerType.Multi) {
                service.settings.viewFinder.checked = false;
            }
        }

        Routing.goTo(Route.Scanning);
    }

    override render(): React.ReactNode {
        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <div style={{ filter: this.props.loading ? "blur(5px)" : "" }}>
                    <NavigationBar rightItem={() => <this.HistoryButton />} />
                    <div style={{ height: ViewUtils.ACTION_BAR_HEIGHT }} />
                    <div style={{ padding: 20, maxWidth: 600, margin: "auto" }}>
                        <BarcodeScannerButtons onButtonClick={this.onBarcodeButtonClick.bind(this)} />

                        <div style={{ height: 16 }}></div>
                        <LearnMoreButton onClick={() => {
                            Routing.goTo(Route.About);
                        }} />
                    </div >
                    {/* <div>{window.navigator.userAgent}</div> */}
                    {/* <div style={{ wordBreak: "break-word", padding: 10 }}>{
                        Platform.isIOS() + "," +
                        ViewUtils.isiPhone14Pro() + ", " +
                        ScanbotSdkService.instance.activeCameraLabel() + ", " +
                        JSON.stringify(ViewUtils.totalPixels())}
                    </div> */}
                </div>

                <BarcodeScannerComponent
                    ref={ref => { this.scanner = ref }}
                    onError={(e: any) => {
                        console.log(e);
                        alert(e);
                        this.scanner?.pop();
                    }}
                />
                {this.props.loading && <FullScreenLoader />}
            </div >

        );
    }
}

export default withRouter(MainPage);