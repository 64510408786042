
/**
 * The values for Scanner Type enum should match image resource, as loading is based on it
 */
export enum BarcodeScannerType {

    OneD = "1d-barcodes",
    TwoD = "2d-barcodes",
    Any = "scan-any-barcode",
    Batch = "batch-scanning",
    Multi = "multiple-scanning"
}