
import React from "react";
import { RxCamera } from "react-icons/rx";
import { CameraInfo } from "scanbot-web-sdk/@types";
import { ActiveCameraInfo, ScanbotSdkService } from "../../../services/scanbot-sdk-service";
import Style from "../../../services/style";
import Translation from "../../../services/translations";
import BaseModalContent from "./subviews/base-modal-content";

export class CameraSelectionRow extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    imageUrl(name: string) {
        return `/assets/misc/icons/${name}.svg`;
    }

    override render(): React.ReactNode {

        const camera = this.props.camera;
        const color = camera.isActive ? Style.BRAND : Style.DARK_GRAY;

        return (
            <div style={{ display: "flex" }} onClick={() => { this.props.onClick(camera) }}>
                {camera.isActive ?
                    <img
                        style={{ marginLeft: 18, width: 18 }}
                        src={this.imageUrl("check-small")}
                        alt=""
                    /> :
                    <div style={{ marginLeft: 18, width: 18 }} />
                }
                <img
                    style={{ marginLeft: 10, fill: "blue", stroke: "green" }}
                    src={this.imageUrl("camera-small-" + (camera.isActive ? "red" : "gray"))}
                    alt=""
                />
                <div style={{ marginLeft: 10, color: color, height: 45, lineHeight: 45 + "px", fontWeight: 400 }}>
                    {camera?.info?.label}
                </div>
            </div>
        );
    }
}

export default class ModalChildCameraSelection extends BaseModalContent {

    constructor(props: any) {
        super(props);

        this.state = {
            title: Translation.texts.selectCameraTitle,
        };
    }

    refreshCameras() {
        this.forceUpdate();
    }

    override Content = (props: any) => {

        const cameras = ScanbotSdkService.instance.getCameraInfo();

        const buttons: any = [];
        for (let i = 0; i < cameras.length; i++) {
            buttons.push(<CameraSelectionRow key={i} camera={cameras[i]} onClick={(camera: CameraInfo) => {
                this.props.onChange(camera)
            }} />)
        }

        return (
            <div>{buttons}</div>
        )
    }
}