import React from "react";
import { RxCaretRight } from "react-icons/rx";

export default class RoundedActionButton extends React.Component<any, any> {

    render(): React.ReactNode {
        const height = this.props.height ?? 45;
        return (
            <div
                style={{
                    backgroundColor: this.props.backgroundColor,
                    width: this.props.width ?? "50%",
                    borderRadius: 5,
                    marginTop: this.props.marginTop ?? 30,
                    marginLeft: this.props.marginLeft ?? (this.props.maxWidth ? "auto" : 0),
                    marginRight: this.props.maxWidth ? "auto" : 0,
                    border: this.props.border,
                    maxWidth: this.props.maxWidth ?? undefined,
                    cursor: "pointer"
                }}
                onClick={this.props.onClick} >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ color: this.props.textColor, height: height, lineHeight: height + "px", fontWeight: 400 }}>
                        {this.props.text}
                    </div>
                    {this.props.caret !== false && <RxCaretRight size={"22"} style={{ color: this.props.textColor, marginTop: 12 }} />}
                    {this.props.iconUrl && <img style={{ marginLeft: 10 }}
                        src={`${this.props.iconUrl}`} alt="image"
                    />}
                </div>
            </div >
        );
    }
}