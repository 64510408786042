
import React from "react";

import { Barcode } from "scanbot-web-sdk/@types";

import ViewUtils from "../../utils/ViewUtils";
import { withRouter } from "../../utils/withRouter";
import NavigationBar from "../subviews/navigation-bar";
import DetailsComponent from "./details-component";

import Routing from "../../services/routing";
import SBStorage from "../../services/storage";
import Translation from "../../services/translations";
import Utils from "../../utils/Utils";

class DetailsPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {};

        Routing.initialize(this.props.navigate);
    }

    override componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    override render(): React.ReactNode {

        let barcode = Routing.params?.barcode;

        if (barcode === undefined) {
            barcode = SBStorage.instance.getById(Utils.getIdParameter())
        }

        if (barcode === undefined) {
            return null;
        }

        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <NavigationBar back={true} text={Translation.texts.barcodeDetails} />
                <div style={{ height: ViewUtils.ACTION_BAR_HEIGHT * 1.5 }} />
                <DetailsComponent
                    visible={true}
                    barcode={barcode}
                    onCopyClick={(code: Barcode) => {
                        navigator.clipboard.writeText(code.text);
                    }}
                    onGoogleClick={(code: Barcode) => {
                        Routing.openTab("https://www.google.com/search?q=" + code.text);
                    }}
                    onOpenUrlClick={(code: Barcode) => {
                        Routing.openTab(barcode?.text);
                    }}
                />
            </div>
        );
    }
}

export default withRouter(DetailsPage);